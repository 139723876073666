.loading {
    position: fixed;
    z-index: 150;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba($white,.6);

    &.hidden {
        display: none;
    }
}

.folding-cube {
    position: absolute;
    top: 50%; left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 40px; height: 40px;

    .cube {
        position: relative;
        float: left;
        width: 50%; height: 50%;
        transform: scale(1.1);

        &:before {
            position: absolute;
            top: 0; left: 0;
            width: 100%; height: 100%;
            content: '';
            background-color: $red;
            animation: foldCubeAngle 2.4s infinite linear both;
            transform-origin: 100% 100%;
        }
    }
    .cube2 {
        transform: scale(1.1) rotateZ(90deg);
        &:before { animation-delay: 0.3s; }
    }
    .cube3 {
        transform: scale(1.1) rotateZ(180deg);
        &:before { animation-delay: 0.6s; }
    }
    .cube4 {
        transform: scale(1.1) rotateZ(270deg);
        &:before { animation-delay: 0.9s; }
    }
}

@-webkit-keyframes foldCubeAngle {
    0%, 10% {
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }
    25%, 75% {
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }
    90%, 100% {
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}

@keyframes foldCubeAngle {
    0%, 10% {
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }
    25%, 75% {
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }
    90%, 100% {
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}
