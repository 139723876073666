input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.form-group {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 15px 0 25px 0;
    line-height: normal;

    input[type=text],
    textarea,
    select {
        display: block;
        width: 100%;
        padding: 6px 2px;
        font-family: $fontLight;
        border: 0;
        border-bottom: 1px solid $grayLight;
        border-radius: 0;
        background: transparent;
        -webkit-appearance: none;
    }

    input[type=search] {
        padding: 11px 50px 11px 20px;
        font-family: $fontRegular;
        font-size: 1.6rem;
        border: 1px solid $blueBright;
        border-radius: 24px;
        background: transparent url('./../../img/common/form/search.svg') no-repeat right 2rem center;
        background-size: 19px;
        -webkit-appearance: none;
    }

    select {
        background: transparent url('./../../img/common/form/selectArrow.svg') no-repeat right .75rem center;
        background-size: 13px 7px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    textarea {
        width: 100%;
        margin-top: 20px;
        padding: 6px;
        border: 1px solid $grayLight;
        border-radius: 5px;
    }

    input[type=text],
    textarea,
    select {
        position: relative;
        z-index: 1;
        // background-color: #f00;
    }

    label {
        position: absolute;
        top: 0; left: 0;
        z-index: 0;
        padding: 0 2px;
        text-align: left;
        color: $grayLight;
    }

    .select2-container {
        font-family: $fontRegular;
        background: transparent;
    
        .select2-selection--single,
        .select2-selection--multiple {
            display: flex;
            height: auto;
            margin: 0;
            border: 0;
            border-radius: 0;
            background: transparent url('./../../img/common/form/selectArrow.svg') no-repeat right .75rem top 12px;
            background-size: 13px 7px;
            .select2-selection__rendered {
                width: 100%;
                min-height: 28px;
                padding: 6px 30px 6px 2px;
                color: $black;
                line-height: normal;
                border-bottom: 1px solid $grayLight;
            }
        }
    
        .select2-selection--single {
            .select2-selection__arrow {
                display: none;
            }
            .select2-selection__clear {
                font-size: 1.6rem;
                line-height: 1;
            }
        }
    
        .select2-selection--multiple {
            min-height: 28px;
            .select2-selection__rendered {
                padding-top: 0;
                padding-bottom: 3px;
            }
        }
    }

    &--inline {
        position: relative;
        display: inline-block;
        width: auto;
        margin-bottom: 0;
        padding: 0;

        input[type=checkbox], input[type=radio] {
            position: absolute;
            top: 0; left: 0;
            opacity: 0;

            & + label {
                position: relative;
                display: flex;
                align-items: center;
                min-height: 16px;
                padding-left: 25px;
                vertical-align: bottom;
                
                &:before {
                    position: absolute;
                    top: 0; left: 0;
                    width: 20px; height: 20px;
                    content: '';
                    background-color: transparent;
                    background-position: 0 0;
                    background-repeat: no-repeat;
                    background-size: 16px 40px;
                }
            }

            &:hover + label:before,
            &:checked + label:before {
                background-position: 0 -24px;
            }
        }

        input[type=checkbox] + label:before { background-image: url('./../../img/common/form/checkbox.svg'); }
        input[type=radio] + label:before { background-image: url('./../../img/common/form/radiobutton.svg'); }

        label { color: $black; }

        & + .form-group--inline {
            padding-left: 50px;
        }
    }

    .errorlist {
        position: absolute;
        bottom: 5px;

        & + input,
        & + select {
            border-color: $red;
        }

        & ~ .select2-container--default {
            .select2-selection--single,
            .select2-selection--multiple {
                .select2-selection__rendered {
                    border-color: $red;
                }
            }   
        }
    }
}

.form-input {
    label {
        position: absolute;
        top: 50%; left: 0;
        transform: translateY(-100%);
        transition: top 0.2s, transform 0.2s;

        display: block;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;            
    }

    &--filled input + label,
    input:focus + label,
    input:-webkit-autofill + label,
    &--filled select + label,
    select:focus + label {
        top: 0;
        transform: translateY(0);
        transition: top 0.2s, transform 0.2s;
    }
}

.errorlist {
    color: $red;
}

.select2-container {
    font-family: $fontLight;
    font-size: 1.4rem;
}