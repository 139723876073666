.mainHome {
    font-size: 1.6rem;
    @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: column;
        flex-grow: 2;
        justify-content: space-evenly;
    }

    .mainHome-line {
        @include media-breakpoint-up(md) {
            display: flex;
            flex-direction: column;
            flex-grow: 2;
        }

        .row {
            width: 100%;
        }

        .mainmainHome-line-title {
            text-align: center;
        }

        .mainHome-line-apps {
            @include media-breakpoint-up(md) {
                display: flex;
                flex-grow: 2;
                flex-wrap: wrap;
            }
        }
    }

    [class*=col] {
        margin-top: 20px;
    }

    .card {
        display: flex;
        justify-content: stretch;
        align-items: stretch;
        height: 100%;
        font-size: 2rem;
        text-align: center;
        border: 1px solid $grayTableBorder;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 20px;
            text-align: center;

            &:hover {
                color: $red;
            }
        }

        &--muted {
            padding: 20px;
            color: $grayTableText;
            background-color: rgba(223, 225, 229, 0.5);
        }
    }
}