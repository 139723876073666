.mainWrapper {
    font-size: 1.2rem;
    .mainWrapper__inner {
        display: flex;
        flex-direction: column;
        margin-top: 60px;
        min-height: calc(100vh);
        @include media-breakpoint-up(md) {
            margin-top: 0;
            margin-left: 91px;
        }
    }
}

.mainContent {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;

    &__header { 
        &__inner {
            margin: 50px 28px 20px;
            @include media-breakpoint-up(lg) {
                display: flex;
                align-items: center;
                margin: 50px 8.333333% 30px;
            }
        }
        &__formDelete {
            float: right;
            @include media-breakpoint-up(lg) {
                margin-left: auto;
            }
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
        flex-grow: 2;
        overflow-x: hidden;
    }
}

.appNav {
    width: 100%; 
    padding: 0 28px;
    background-color: $white;
    border-bottom: 1px solid $grayWhite;

    @include media-breakpoint-up(md) {
        height: 70px;
    }

    &__toggler {
        position: relative;
        z-index: 2;
        width: 30px; height: 30px;
        padding: 0; margin-top: 5px;
        border: 0;
        background-color: transparent;

        @include media-breakpoint-up(md) {
            display: none;
        }

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            display: block;
            width: 30px; height: 2px;
            background-color: $blueBright;
            pointer-events: none;
            transition: background-color .2s ease;
            will-change: background-color;

            // @include media-breakpoint-up(sm) {
            //     width: 43px; height: 4px;
            // }

            &:before, &:after {
                position: absolute;
                left: 0;
                content: "";
                display: block;
                width: 30px; height: 2px;
                background-color: $blueBright;
                will-change: top, bottom, transform;

                // @include media-breakpoint-up(sm) {
                //     width: 43px; height: 4px;
                // }
            }

            &::before {
                top: -8px;
                transition: top .2s ease,transform .2s ease;

                // @include media-breakpoint-up(sm) {
                //     top: -9px;
                // }
            }
            &:after {
                bottom: -8px;
                transition: bottom .2s ease,transform .2s ease;

                // @include media-breakpoint-up(sm) {
                //     bottom: -9px;
                // }
            }
        }

        &.open {
            span {
                background: none;
                &:before {
                    top: 0;
                    transform: rotate(45deg);
                }
                &:after {
                    bottom: 0;
                    transform: rotate(-45deg);
                }
            }
        }
    }

    &__list {
        display: none;
        font-size: 1.4rem;

        @include media-breakpoint-up(md) {
            display: flex;
            align-items: center;
            width: 100%; height: 100%;
            font-size: 1.4rem;

            li + li { 
                margin-left: 40px;
                &.appNav__list__rightItem {
                    margin-left: auto;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            font-size: 2rem;
        }

        a {
            color: $grayMedium;

            &.active {
                display: inline-block;
                color: $blueBright;
                border-bottom: 1px solid $blueBright;
                
                @include media-breakpoint-up(md) {
                    position: relative;
                    line-height: 70px;
                    border-bottom: 0;
                    
                    &:after {
                        position: absolute;
                        left: 0; bottom: 0;
                        width: 100%; height: 4px;
                        content: '';
                        background-color: $blueBright;
                    }
                }
            }
        }
    }

    &.showMenu {
        height: auto;
        padding-bottom: 15px;
        
        .appNav__list {
            display: block;
        }
    }
}