.mainHeader {
    position: fixed;
    top: 0; left: 0;
    z-index: 50;
    width: 100vw; height: 60px;
    background-color: $red;

    @include media-breakpoint-up(md) {
        width: 91px; height: 100vh;
    }

    .logo {
        position: absolute;
        top: 50%; left: 50%;
        width: 230px; height: 17px;
        transform: translateX(-50%) translateY(-50%);
        
        @include media-breakpoint-up(md) {
            transform: translateX(-50%) translateY(-50%) rotate(-90deg);
            transform-origin: center center;            
        }
    }

    
    .userName {
        position: absolute;
        top: 50%; left: 20px;
        display: block;
        width: 42px; height: 42px;
        overflow: hidden;
        line-height: 42px;
        text-align: center;
        transform: translateY(-50%);

        @include media-breakpoint-up(md) {
            top: 30px; left: 50%;
            transform: translateX(-50%);
        }

        &:before {
            position: absolute;
            top: 0; left: 0;
            z-index: -1;
            display: block;
            width: 42px; height: 42px;
            content: '';
            background-color: $white;
            border-radius: 50%;
        }
    }

    .logout {
        position: absolute;
        top: 50%; right: 20px;
        display: block;
        transform: translateX(-100%) translateY(-50%);
        @include media-breakpoint-up(md) {
            right: auto; top: auto;
            bottom: 30px; left: 50%;
            transform: translateX(-50%);
        }
    }
}