/* popin */
@keyframes popin-open {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
@keyframes popin-close {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

.popin {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0; right: 0; left: 91px;
    z-index: 20000;
    width: 100%; height: 100vh;
    background: rgba(56, 59, 64, 0.7);

    &.open {
        display: flex;
        animation: popin-open 0.3s ease both;
    }

    &.close {
        animation: popin-close 0.3s ease both;
    }

    &__inner {
        position: relative;
    }
    
    &__box {
        height: 100%;
        padding: 50px 90px;
        overflow-y: auto;   
        background: $white;
        border-radius: 10px;
        box-shadow: 0 0 20px 0 rgba(0,0,0,0.30);
    
        &--small {
            width: 480px;
        }
    }

    &__title {
        font-family: $fontBold;
        font-size: 3.6rem;
        color: $red;
        text-align: center;
    }

    &__message {
        font-size: 2rem;
        text-align: center;
    }
    
    &__close {
        display: flex;
        margin: auto;
        cursor: pointer;

        &--default {
            position: absolute;
            top: 50px; right: 90px;
            z-index: 10;
            display: block;
            width: 20px; height: 20px;
            background: transparent url('./../../img/common/pics/cross.svg') no-repeat 0 center;
            background-size: 20px 88px;
        }
    }
}
