.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

/*
 * Bootstrap Grid v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}
.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*=col-] {
    padding-right: 0;
    padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}

.col-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.col-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}

.col-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}

.col-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.col-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}

.col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}

.col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}

.col-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
}

.col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.order-first {
    -ms-flex-order: -1;
    order: -1;
}

.order-last {
    -ms-flex-order: 13;
    order: 13;
}

.order-0 {
    -ms-flex-order: 0;
    order: 0;
}

.order-1 {
    -ms-flex-order: 1;
    order: 1;
}

.order-2 {
    -ms-flex-order: 2;
    order: 2;
}

.order-3 {
    -ms-flex-order: 3;
    order: 3;
}

.order-4 {
    -ms-flex-order: 4;
    order: 4;
}

.order-5 {
    -ms-flex-order: 5;
    order: 5;
}

.order-6 {
    -ms-flex-order: 6;
    order: 6;
}

.order-7 {
    -ms-flex-order: 7;
    order: 7;
}

.order-8 {
    -ms-flex-order: 8;
    order: 8;
}

.order-9 {
    -ms-flex-order: 9;
    order: 9;
}

.order-10 {
    -ms-flex-order: 10;
    order: 10;
}

.order-11 {
    -ms-flex-order: 11;
    order: 11;
}

.order-12 {
    -ms-flex-order: 12;
    order: 12;
}

.offset-1 {
    margin-left: 8.333333%;
}

.offset-2 {
    margin-left: 16.666667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4 {
    margin-left: 33.333333%;
}

.offset-5 {
    margin-left: 41.666667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.333333%;
}

.offset-8 {
    margin-left: 66.666667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.333333%;
}

.offset-11 {
    margin-left: 91.666667%;
}

@media (min-width: 576px) {
    .col-sm {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-sm-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-sm-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .col-sm-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-sm-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-sm-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-sm-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-sm-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-sm-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .col-sm-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-sm-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-sm-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .col-sm-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .col-sm-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-sm-first {
        -ms-flex-order: -1;
        order: -1;
    }

    .order-sm-last {
        -ms-flex-order: 13;
        order: 13;
    }

    .order-sm-0 {
        -ms-flex-order: 0;
        order: 0;
    }

    .order-sm-1 {
        -ms-flex-order: 1;
        order: 1;
    }

    .order-sm-2 {
        -ms-flex-order: 2;
        order: 2;
    }

    .order-sm-3 {
        -ms-flex-order: 3;
        order: 3;
    }

    .order-sm-4 {
        -ms-flex-order: 4;
        order: 4;
    }

    .order-sm-5 {
        -ms-flex-order: 5;
        order: 5;
    }

    .order-sm-6 {
        -ms-flex-order: 6;
        order: 6;
    }

    .order-sm-7 {
        -ms-flex-order: 7;
        order: 7;
    }

    .order-sm-8 {
        -ms-flex-order: 8;
        order: 8;
    }

    .order-sm-9 {
        -ms-flex-order: 9;
        order: 9;
    }

    .order-sm-10 {
        -ms-flex-order: 10;
        order: 10;
    }

    .order-sm-11 {
        -ms-flex-order: 11;
        order: 11;
    }

    .order-sm-12 {
        -ms-flex-order: 12;
        order: 12;
    }

    .offset-sm-0 {
        margin-left: 0;
    }

    .offset-sm-1 {
        margin-left: 8.333333%;
    }

    .offset-sm-2 {
        margin-left: 16.666667%;
    }

    .offset-sm-3 {
        margin-left: 25%;
    }

    .offset-sm-4 {
        margin-left: 33.333333%;
    }

    .offset-sm-5 {
        margin-left: 41.666667%;
    }

    .offset-sm-6 {
        margin-left: 50%;
    }

    .offset-sm-7 {
        margin-left: 58.333333%;
    }

    .offset-sm-8 {
        margin-left: 66.666667%;
    }

    .offset-sm-9 {
        margin-left: 75%;
    }

    .offset-sm-10 {
        margin-left: 83.333333%;
    }

    .offset-sm-11 {
        margin-left: 91.666667%;
    }
}
@media (min-width: 768px) {
    .col-md {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-md-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-md-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .col-md-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-md-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-md-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-md-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-md-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-md-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .col-md-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-md-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-md-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .col-md-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .col-md-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-md-first {
        -ms-flex-order: -1;
        order: -1;
    }

    .order-md-last {
        -ms-flex-order: 13;
        order: 13;
    }

    .order-md-0 {
        -ms-flex-order: 0;
        order: 0;
    }

    .order-md-1 {
        -ms-flex-order: 1;
        order: 1;
    }

    .order-md-2 {
        -ms-flex-order: 2;
        order: 2;
    }

    .order-md-3 {
        -ms-flex-order: 3;
        order: 3;
    }

    .order-md-4 {
        -ms-flex-order: 4;
        order: 4;
    }

    .order-md-5 {
        -ms-flex-order: 5;
        order: 5;
    }

    .order-md-6 {
        -ms-flex-order: 6;
        order: 6;
    }

    .order-md-7 {
        -ms-flex-order: 7;
        order: 7;
    }

    .order-md-8 {
        -ms-flex-order: 8;
        order: 8;
    }

    .order-md-9 {
        -ms-flex-order: 9;
        order: 9;
    }

    .order-md-10 {
        -ms-flex-order: 10;
        order: 10;
    }

    .order-md-11 {
        -ms-flex-order: 11;
        order: 11;
    }

    .order-md-12 {
        -ms-flex-order: 12;
        order: 12;
    }

    .offset-md-0 {
        margin-left: 0;
    }

    .offset-md-1 {
        margin-left: 8.333333%;
    }

    .offset-md-2 {
        margin-left: 16.666667%;
    }

    .offset-md-3 {
        margin-left: 25%;
    }

    .offset-md-4 {
        margin-left: 33.333333%;
    }

    .offset-md-5 {
        margin-left: 41.666667%;
    }

    .offset-md-6 {
        margin-left: 50%;
    }

    .offset-md-7 {
        margin-left: 58.333333%;
    }

    .offset-md-8 {
        margin-left: 66.666667%;
    }

    .offset-md-9 {
        margin-left: 75%;
    }

    .offset-md-10 {
        margin-left: 83.333333%;
    }

    .offset-md-11 {
        margin-left: 91.666667%;
    }
}
@media (min-width: 992px) {
    .col-lg {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-lg-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-lg-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .col-lg-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-lg-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-lg-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-lg-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-lg-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .col-lg-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-lg-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-lg-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .col-lg-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .col-lg-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-lg-first {
        -ms-flex-order: -1;
        order: -1;
    }

    .order-lg-last {
        -ms-flex-order: 13;
        order: 13;
    }

    .order-lg-0 {
        -ms-flex-order: 0;
        order: 0;
    }

    .order-lg-1 {
        -ms-flex-order: 1;
        order: 1;
    }

    .order-lg-2 {
        -ms-flex-order: 2;
        order: 2;
    }

    .order-lg-3 {
        -ms-flex-order: 3;
        order: 3;
    }

    .order-lg-4 {
        -ms-flex-order: 4;
        order: 4;
    }

    .order-lg-5 {
        -ms-flex-order: 5;
        order: 5;
    }

    .order-lg-6 {
        -ms-flex-order: 6;
        order: 6;
    }

    .order-lg-7 {
        -ms-flex-order: 7;
        order: 7;
    }

    .order-lg-8 {
        -ms-flex-order: 8;
        order: 8;
    }

    .order-lg-9 {
        -ms-flex-order: 9;
        order: 9;
    }

    .order-lg-10 {
        -ms-flex-order: 10;
        order: 10;
    }

    .order-lg-11 {
        -ms-flex-order: 11;
        order: 11;
    }

    .order-lg-12 {
        -ms-flex-order: 12;
        order: 12;
    }

    .offset-lg-0 {
        margin-left: 0;
    }

    .offset-lg-1 {
        margin-left: 8.333333%;
    }

    .offset-lg-2 {
        margin-left: 16.666667%;
    }

    .offset-lg-3 {
        margin-left: 25%;
    }

    .offset-lg-4 {
        margin-left: 33.333333%;
    }

    .offset-lg-5 {
        margin-left: 41.666667%;
    }

    .offset-lg-6 {
        margin-left: 50%;
    }

    .offset-lg-7 {
        margin-left: 58.333333%;
    }

    .offset-lg-8 {
        margin-left: 66.666667%;
    }

    .offset-lg-9 {
        margin-left: 75%;
    }

    .offset-lg-10 {
        margin-left: 83.333333%;
    }

    .offset-lg-11 {
        margin-left: 91.666667%;
    }
}
@media (min-width: 1200px) {
    .col-xl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-xl-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-xl-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .col-xl-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-xl-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xl-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-xl-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-xl-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xl-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .col-xl-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-xl-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xl-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .col-xl-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .col-xl-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-xl-first {
        -ms-flex-order: -1;
        order: -1;
    }

    .order-xl-last {
        -ms-flex-order: 13;
        order: 13;
    }

    .order-xl-0 {
        -ms-flex-order: 0;
        order: 0;
    }

    .order-xl-1 {
        -ms-flex-order: 1;
        order: 1;
    }

    .order-xl-2 {
        -ms-flex-order: 2;
        order: 2;
    }

    .order-xl-3 {
        -ms-flex-order: 3;
        order: 3;
    }

    .order-xl-4 {
        -ms-flex-order: 4;
        order: 4;
    }

    .order-xl-5 {
        -ms-flex-order: 5;
        order: 5;
    }

    .order-xl-6 {
        -ms-flex-order: 6;
        order: 6;
    }

    .order-xl-7 {
        -ms-flex-order: 7;
        order: 7;
    }

    .order-xl-8 {
        -ms-flex-order: 8;
        order: 8;
    }

    .order-xl-9 {
        -ms-flex-order: 9;
        order: 9;
    }

    .order-xl-10 {
        -ms-flex-order: 10;
        order: 10;
    }

    .order-xl-11 {
        -ms-flex-order: 11;
        order: 11;
    }

    .order-xl-12 {
        -ms-flex-order: 12;
        order: 12;
    }

    .offset-xl-0 {
        margin-left: 0;
    }

    .offset-xl-1 {
        margin-left: 8.333333%;
    }

    .offset-xl-2 {
        margin-left: 16.666667%;
    }

    .offset-xl-3 {
        margin-left: 25%;
    }

    .offset-xl-4 {
        margin-left: 33.333333%;
    }

    .offset-xl-5 {
        margin-left: 41.666667%;
    }

    .offset-xl-6 {
        margin-left: 50%;
    }

    .offset-xl-7 {
        margin-left: 58.333333%;
    }

    .offset-xl-8 {
        margin-left: 66.666667%;
    }

    .offset-xl-9 {
        margin-left: 75%;
    }

    .offset-xl-10 {
        margin-left: 83.333333%;
    }

    .offset-xl-11 {
        margin-left: 91.666667%;
    }
}
.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}

.d-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
}

@media (min-width: 576px) {
    .d-sm-none {
        display: none !important;
    }

    .d-sm-inline {
        display: inline !important;
    }

    .d-sm-inline-block {
        display: inline-block !important;
    }

    .d-sm-block {
        display: block !important;
    }

    .d-sm-table {
        display: table !important;
    }

    .d-sm-table-row {
        display: table-row !important;
    }

    .d-sm-table-cell {
        display: table-cell !important;
    }

    .d-sm-flex {
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-sm-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}
@media (min-width: 768px) {
    .d-md-none {
        display: none !important;
    }

    .d-md-inline {
        display: inline !important;
    }

    .d-md-inline-block {
        display: inline-block !important;
    }

    .d-md-block {
        display: block !important;
    }

    .d-md-table {
        display: table !important;
    }

    .d-md-table-row {
        display: table-row !important;
    }

    .d-md-table-cell {
        display: table-cell !important;
    }

    .d-md-flex {
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-md-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}
@media (min-width: 992px) {
    .d-lg-none {
        display: none !important;
    }

    .d-lg-inline {
        display: inline !important;
    }

    .d-lg-inline-block {
        display: inline-block !important;
    }

    .d-lg-block {
        display: block !important;
    }

    .d-lg-table {
        display: table !important;
    }

    .d-lg-table-row {
        display: table-row !important;
    }

    .d-lg-table-cell {
        display: table-cell !important;
    }

    .d-lg-flex {
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-lg-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}
@media (min-width: 1200px) {
    .d-xl-none {
        display: none !important;
    }

    .d-xl-inline {
        display: inline !important;
    }

    .d-xl-inline-block {
        display: inline-block !important;
    }

    .d-xl-block {
        display: block !important;
    }

    .d-xl-table {
        display: table !important;
    }

    .d-xl-table-row {
        display: table-row !important;
    }

    .d-xl-table-cell {
        display: table-cell !important;
    }

    .d-xl-flex {
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-xl-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}
@media print {
    .d-print-none {
        display: none !important;
    }

    .d-print-inline {
        display: inline !important;
    }

    .d-print-inline-block {
        display: inline-block !important;
    }

    .d-print-block {
        display: block !important;
    }

    .d-print-table {
        display: table !important;
    }

    .d-print-table-row {
        display: table-row !important;
    }

    .d-print-table-cell {
        display: table-cell !important;
    }

    .d-print-flex {
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-print-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}
.flex-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
}

.flex-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
}

.flex-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
}

.flex-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
}

.flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
}

.flex-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
}

.flex-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
}

.flex-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
}

.flex-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
}

.flex-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
}

.flex-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
}

.justify-content-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
}

.justify-content-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
}

.justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
}

.justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
}

.align-items-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
}

.align-items-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}

.align-items-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
}

.align-items-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
}

.align-content-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
}

.align-content-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
}

.align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
}

.align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
}

.align-content-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
}

.align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
}

.align-self-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
}

.align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
}

.align-self-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
}

.align-self-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
}

.align-self-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
}

.align-self-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
}

@media (min-width: 576px) {
    .flex-sm-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    .flex-sm-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    .flex-sm-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }

    .flex-sm-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }

    .flex-sm-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }

    .flex-sm-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }

    .flex-sm-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }

    .flex-sm-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }

    .flex-sm-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }

    .flex-sm-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }

    .flex-sm-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }

    .flex-sm-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }

    .justify-content-sm-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-sm-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-sm-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-sm-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-sm-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }

    .align-items-sm-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .align-items-sm-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-sm-center {
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .align-items-sm-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }

    .align-items-sm-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    .align-content-sm-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }

    .align-content-sm-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }

    .align-content-sm-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }

    .align-content-sm-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }

    .align-content-sm-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }

    .align-content-sm-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }

    .align-self-sm-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }

    .align-self-sm-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }

    .align-self-sm-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .align-self-sm-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }

    .align-self-sm-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }

    .align-self-sm-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }
}
@media (min-width: 768px) {
    .flex-md-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    .flex-md-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    .flex-md-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }

    .flex-md-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }

    .flex-md-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }

    .flex-md-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }

    .flex-md-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }

    .flex-md-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }

    .flex-md-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }

    .flex-md-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }

    .flex-md-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }

    .flex-md-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }

    .justify-content-md-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-md-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-md-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-md-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-md-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }

    .align-items-md-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .align-items-md-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-md-center {
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .align-items-md-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }

    .align-items-md-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    .align-content-md-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }

    .align-content-md-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }

    .align-content-md-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }

    .align-content-md-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }

    .align-content-md-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }

    .align-content-md-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }

    .align-self-md-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }

    .align-self-md-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }

    .align-self-md-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .align-self-md-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }

    .align-self-md-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }

    .align-self-md-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }
}
@media (min-width: 992px) {
    .flex-lg-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    .flex-lg-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    .flex-lg-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }

    .flex-lg-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }

    .flex-lg-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }

    .flex-lg-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }

    .flex-lg-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }

    .flex-lg-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }

    .flex-lg-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }

    .flex-lg-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }

    .flex-lg-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }

    .flex-lg-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }

    .justify-content-lg-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-lg-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-lg-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-lg-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-lg-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }

    .align-items-lg-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .align-items-lg-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-lg-center {
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .align-items-lg-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }

    .align-items-lg-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    .align-content-lg-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }

    .align-content-lg-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }

    .align-content-lg-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }

    .align-content-lg-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }

    .align-content-lg-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }

    .align-content-lg-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }

    .align-self-lg-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }

    .align-self-lg-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }

    .align-self-lg-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .align-self-lg-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }

    .align-self-lg-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }

    .align-self-lg-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }
}
@media (min-width: 1200px) {
    .flex-xl-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    .flex-xl-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    .flex-xl-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }

    .flex-xl-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }

    .flex-xl-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }

    .flex-xl-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }

    .flex-xl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }

    .flex-xl-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }

    .flex-xl-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }

    .flex-xl-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }

    .flex-xl-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }

    .flex-xl-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }

    .justify-content-xl-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-xl-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-xl-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-xl-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-xl-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }

    .align-items-xl-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .align-items-xl-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-xl-center {
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .align-items-xl-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }

    .align-items-xl-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    .align-content-xl-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }

    .align-content-xl-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }

    .align-content-xl-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }

    .align-content-xl-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }

    .align-content-xl-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }

    .align-content-xl-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }

    .align-self-xl-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }

    .align-self-xl-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }

    .align-self-xl-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .align-self-xl-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }

    .align-self-xl-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }

    .align-self-xl-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }
}
.m-0 {
    margin: 0 !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.mr-0,
.mx-0 {
    margin-right: 0 !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
    margin-left: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
    margin-left: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2,
.my-2 {
    margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pr-0,
.px-0 {
    padding-right: 0 !important;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
    padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
    padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
    padding-left: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3,
.py-3 {
    padding-top: 1rem !important;
}

.pr-3,
.px-3 {
    padding-right: 1rem !important;
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5,
.py-5 {
    padding-top: 3rem !important;
}

.pr-5,
.px-5 {
    padding-right: 3rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
    padding-left: 3rem !important;
}

.m-n1 {
    margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
    margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
    margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
    margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
    margin-left: -0.25rem !important;
}

.m-n2 {
    margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
    margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
    margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
    margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
    margin-left: -0.5rem !important;
}

.m-n3 {
    margin: -1rem !important;
}

.mt-n3,
.my-n3 {
    margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
    margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
    margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
    margin-left: -1rem !important;
}

.m-n4 {
    margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
    margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
    margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
    margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
    margin-left: -1.5rem !important;
}

.m-n5 {
    margin: -3rem !important;
}

.mt-n5,
.my-n5 {
    margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
    margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
    margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
    margin-left: -3rem !important;
}

.m-auto {
    margin: auto !important;
}

.mt-auto,
.my-auto {
    margin-top: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

@media (min-width: 576px) {
    .m-sm-0 {
        margin: 0 !important;
    }

    .mt-sm-0,
.my-sm-0 {
        margin-top: 0 !important;
    }

    .mr-sm-0,
.mx-sm-0 {
        margin-right: 0 !important;
    }

    .mb-sm-0,
.my-sm-0 {
        margin-bottom: 0 !important;
    }

    .ml-sm-0,
.mx-sm-0 {
        margin-left: 0 !important;
    }

    .m-sm-1 {
        margin: 0.25rem !important;
    }

    .mt-sm-1,
.my-sm-1 {
        margin-top: 0.25rem !important;
    }

    .mr-sm-1,
.mx-sm-1 {
        margin-right: 0.25rem !important;
    }

    .mb-sm-1,
.my-sm-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-sm-1,
.mx-sm-1 {
        margin-left: 0.25rem !important;
    }

    .m-sm-2 {
        margin: 0.5rem !important;
    }

    .mt-sm-2,
.my-sm-2 {
        margin-top: 0.5rem !important;
    }

    .mr-sm-2,
.mx-sm-2 {
        margin-right: 0.5rem !important;
    }

    .mb-sm-2,
.my-sm-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-sm-2,
.mx-sm-2 {
        margin-left: 0.5rem !important;
    }

    .m-sm-3 {
        margin: 1rem !important;
    }

    .mt-sm-3,
.my-sm-3 {
        margin-top: 1rem !important;
    }

    .mr-sm-3,
.mx-sm-3 {
        margin-right: 1rem !important;
    }

    .mb-sm-3,
.my-sm-3 {
        margin-bottom: 1rem !important;
    }

    .ml-sm-3,
.mx-sm-3 {
        margin-left: 1rem !important;
    }

    .m-sm-4 {
        margin: 1.5rem !important;
    }

    .mt-sm-4,
.my-sm-4 {
        margin-top: 1.5rem !important;
    }

    .mr-sm-4,
.mx-sm-4 {
        margin-right: 1.5rem !important;
    }

    .mb-sm-4,
.my-sm-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-sm-4,
.mx-sm-4 {
        margin-left: 1.5rem !important;
    }

    .m-sm-5 {
        margin: 3rem !important;
    }

    .mt-sm-5,
.my-sm-5 {
        margin-top: 3rem !important;
    }

    .mr-sm-5,
.mx-sm-5 {
        margin-right: 3rem !important;
    }

    .mb-sm-5,
.my-sm-5 {
        margin-bottom: 3rem !important;
    }

    .ml-sm-5,
.mx-sm-5 {
        margin-left: 3rem !important;
    }

    .p-sm-0 {
        padding: 0 !important;
    }

    .pt-sm-0,
.py-sm-0 {
        padding-top: 0 !important;
    }

    .pr-sm-0,
.px-sm-0 {
        padding-right: 0 !important;
    }

    .pb-sm-0,
.py-sm-0 {
        padding-bottom: 0 !important;
    }

    .pl-sm-0,
.px-sm-0 {
        padding-left: 0 !important;
    }

    .p-sm-1 {
        padding: 0.25rem !important;
    }

    .pt-sm-1,
.py-sm-1 {
        padding-top: 0.25rem !important;
    }

    .pr-sm-1,
.px-sm-1 {
        padding-right: 0.25rem !important;
    }

    .pb-sm-1,
.py-sm-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-sm-1,
.px-sm-1 {
        padding-left: 0.25rem !important;
    }

    .p-sm-2 {
        padding: 0.5rem !important;
    }

    .pt-sm-2,
.py-sm-2 {
        padding-top: 0.5rem !important;
    }

    .pr-sm-2,
.px-sm-2 {
        padding-right: 0.5rem !important;
    }

    .pb-sm-2,
.py-sm-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-sm-2,
.px-sm-2 {
        padding-left: 0.5rem !important;
    }

    .p-sm-3 {
        padding: 1rem !important;
    }

    .pt-sm-3,
.py-sm-3 {
        padding-top: 1rem !important;
    }

    .pr-sm-3,
.px-sm-3 {
        padding-right: 1rem !important;
    }

    .pb-sm-3,
.py-sm-3 {
        padding-bottom: 1rem !important;
    }

    .pl-sm-3,
.px-sm-3 {
        padding-left: 1rem !important;
    }

    .p-sm-4 {
        padding: 1.5rem !important;
    }

    .pt-sm-4,
.py-sm-4 {
        padding-top: 1.5rem !important;
    }

    .pr-sm-4,
.px-sm-4 {
        padding-right: 1.5rem !important;
    }

    .pb-sm-4,
.py-sm-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-sm-4,
.px-sm-4 {
        padding-left: 1.5rem !important;
    }

    .p-sm-5 {
        padding: 3rem !important;
    }

    .pt-sm-5,
.py-sm-5 {
        padding-top: 3rem !important;
    }

    .pr-sm-5,
.px-sm-5 {
        padding-right: 3rem !important;
    }

    .pb-sm-5,
.py-sm-5 {
        padding-bottom: 3rem !important;
    }

    .pl-sm-5,
.px-sm-5 {
        padding-left: 3rem !important;
    }

    .m-sm-n1 {
        margin: -0.25rem !important;
    }

    .mt-sm-n1,
.my-sm-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-sm-n1,
.mx-sm-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-sm-n1,
.my-sm-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-sm-n1,
.mx-sm-n1 {
        margin-left: -0.25rem !important;
    }

    .m-sm-n2 {
        margin: -0.5rem !important;
    }

    .mt-sm-n2,
.my-sm-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-sm-n2,
.mx-sm-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-sm-n2,
.my-sm-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-sm-n2,
.mx-sm-n2 {
        margin-left: -0.5rem !important;
    }

    .m-sm-n3 {
        margin: -1rem !important;
    }

    .mt-sm-n3,
.my-sm-n3 {
        margin-top: -1rem !important;
    }

    .mr-sm-n3,
.mx-sm-n3 {
        margin-right: -1rem !important;
    }

    .mb-sm-n3,
.my-sm-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-sm-n3,
.mx-sm-n3 {
        margin-left: -1rem !important;
    }

    .m-sm-n4 {
        margin: -1.5rem !important;
    }

    .mt-sm-n4,
.my-sm-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-sm-n4,
.mx-sm-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-sm-n4,
.my-sm-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-sm-n4,
.mx-sm-n4 {
        margin-left: -1.5rem !important;
    }

    .m-sm-n5 {
        margin: -3rem !important;
    }

    .mt-sm-n5,
.my-sm-n5 {
        margin-top: -3rem !important;
    }

    .mr-sm-n5,
.mx-sm-n5 {
        margin-right: -3rem !important;
    }

    .mb-sm-n5,
.my-sm-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-sm-n5,
.mx-sm-n5 {
        margin-left: -3rem !important;
    }

    .m-sm-auto {
        margin: auto !important;
    }

    .mt-sm-auto,
.my-sm-auto {
        margin-top: auto !important;
    }

    .mr-sm-auto,
.mx-sm-auto {
        margin-right: auto !important;
    }

    .mb-sm-auto,
.my-sm-auto {
        margin-bottom: auto !important;
    }

    .ml-sm-auto,
.mx-sm-auto {
        margin-left: auto !important;
    }
}
@media (min-width: 768px) {
    .m-md-0 {
        margin: 0 !important;
    }

    .mt-md-0,
.my-md-0 {
        margin-top: 0 !important;
    }

    .mr-md-0,
.mx-md-0 {
        margin-right: 0 !important;
    }

    .mb-md-0,
.my-md-0 {
        margin-bottom: 0 !important;
    }

    .ml-md-0,
.mx-md-0 {
        margin-left: 0 !important;
    }

    .m-md-1 {
        margin: 0.25rem !important;
    }

    .mt-md-1,
.my-md-1 {
        margin-top: 0.25rem !important;
    }

    .mr-md-1,
.mx-md-1 {
        margin-right: 0.25rem !important;
    }

    .mb-md-1,
.my-md-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-md-1,
.mx-md-1 {
        margin-left: 0.25rem !important;
    }

    .m-md-2 {
        margin: 0.5rem !important;
    }

    .mt-md-2,
.my-md-2 {
        margin-top: 0.5rem !important;
    }

    .mr-md-2,
.mx-md-2 {
        margin-right: 0.5rem !important;
    }

    .mb-md-2,
.my-md-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-md-2,
.mx-md-2 {
        margin-left: 0.5rem !important;
    }

    .m-md-3 {
        margin: 1rem !important;
    }

    .mt-md-3,
.my-md-3 {
        margin-top: 1rem !important;
    }

    .mr-md-3,
.mx-md-3 {
        margin-right: 1rem !important;
    }

    .mb-md-3,
.my-md-3 {
        margin-bottom: 1rem !important;
    }

    .ml-md-3,
.mx-md-3 {
        margin-left: 1rem !important;
    }

    .m-md-4 {
        margin: 1.5rem !important;
    }

    .mt-md-4,
.my-md-4 {
        margin-top: 1.5rem !important;
    }

    .mr-md-4,
.mx-md-4 {
        margin-right: 1.5rem !important;
    }

    .mb-md-4,
.my-md-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-md-4,
.mx-md-4 {
        margin-left: 1.5rem !important;
    }

    .m-md-5 {
        margin: 3rem !important;
    }

    .mt-md-5,
.my-md-5 {
        margin-top: 3rem !important;
    }

    .mr-md-5,
.mx-md-5 {
        margin-right: 3rem !important;
    }

    .mb-md-5,
.my-md-5 {
        margin-bottom: 3rem !important;
    }

    .ml-md-5,
.mx-md-5 {
        margin-left: 3rem !important;
    }

    .p-md-0 {
        padding: 0 !important;
    }

    .pt-md-0,
.py-md-0 {
        padding-top: 0 !important;
    }

    .pr-md-0,
.px-md-0 {
        padding-right: 0 !important;
    }

    .pb-md-0,
.py-md-0 {
        padding-bottom: 0 !important;
    }

    .pl-md-0,
.px-md-0 {
        padding-left: 0 !important;
    }

    .p-md-1 {
        padding: 0.25rem !important;
    }

    .pt-md-1,
.py-md-1 {
        padding-top: 0.25rem !important;
    }

    .pr-md-1,
.px-md-1 {
        padding-right: 0.25rem !important;
    }

    .pb-md-1,
.py-md-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-md-1,
.px-md-1 {
        padding-left: 0.25rem !important;
    }

    .p-md-2 {
        padding: 0.5rem !important;
    }

    .pt-md-2,
.py-md-2 {
        padding-top: 0.5rem !important;
    }

    .pr-md-2,
.px-md-2 {
        padding-right: 0.5rem !important;
    }

    .pb-md-2,
.py-md-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-md-2,
.px-md-2 {
        padding-left: 0.5rem !important;
    }

    .p-md-3 {
        padding: 1rem !important;
    }

    .pt-md-3,
.py-md-3 {
        padding-top: 1rem !important;
    }

    .pr-md-3,
.px-md-3 {
        padding-right: 1rem !important;
    }

    .pb-md-3,
.py-md-3 {
        padding-bottom: 1rem !important;
    }

    .pl-md-3,
.px-md-3 {
        padding-left: 1rem !important;
    }

    .p-md-4 {
        padding: 1.5rem !important;
    }

    .pt-md-4,
.py-md-4 {
        padding-top: 1.5rem !important;
    }

    .pr-md-4,
.px-md-4 {
        padding-right: 1.5rem !important;
    }

    .pb-md-4,
.py-md-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-md-4,
.px-md-4 {
        padding-left: 1.5rem !important;
    }

    .p-md-5 {
        padding: 3rem !important;
    }

    .pt-md-5,
.py-md-5 {
        padding-top: 3rem !important;
    }

    .pr-md-5,
.px-md-5 {
        padding-right: 3rem !important;
    }

    .pb-md-5,
.py-md-5 {
        padding-bottom: 3rem !important;
    }

    .pl-md-5,
.px-md-5 {
        padding-left: 3rem !important;
    }

    .m-md-n1 {
        margin: -0.25rem !important;
    }

    .mt-md-n1,
.my-md-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-md-n1,
.mx-md-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-md-n1,
.my-md-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-md-n1,
.mx-md-n1 {
        margin-left: -0.25rem !important;
    }

    .m-md-n2 {
        margin: -0.5rem !important;
    }

    .mt-md-n2,
.my-md-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-md-n2,
.mx-md-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-md-n2,
.my-md-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-md-n2,
.mx-md-n2 {
        margin-left: -0.5rem !important;
    }

    .m-md-n3 {
        margin: -1rem !important;
    }

    .mt-md-n3,
.my-md-n3 {
        margin-top: -1rem !important;
    }

    .mr-md-n3,
.mx-md-n3 {
        margin-right: -1rem !important;
    }

    .mb-md-n3,
.my-md-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-md-n3,
.mx-md-n3 {
        margin-left: -1rem !important;
    }

    .m-md-n4 {
        margin: -1.5rem !important;
    }

    .mt-md-n4,
.my-md-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-md-n4,
.mx-md-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-md-n4,
.my-md-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-md-n4,
.mx-md-n4 {
        margin-left: -1.5rem !important;
    }

    .m-md-n5 {
        margin: -3rem !important;
    }

    .mt-md-n5,
.my-md-n5 {
        margin-top: -3rem !important;
    }

    .mr-md-n5,
.mx-md-n5 {
        margin-right: -3rem !important;
    }

    .mb-md-n5,
.my-md-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-md-n5,
.mx-md-n5 {
        margin-left: -3rem !important;
    }

    .m-md-auto {
        margin: auto !important;
    }

    .mt-md-auto,
.my-md-auto {
        margin-top: auto !important;
    }

    .mr-md-auto,
.mx-md-auto {
        margin-right: auto !important;
    }

    .mb-md-auto,
.my-md-auto {
        margin-bottom: auto !important;
    }

    .ml-md-auto,
.mx-md-auto {
        margin-left: auto !important;
    }
}
@media (min-width: 992px) {
    .m-lg-0 {
        margin: 0 !important;
    }

    .mt-lg-0,
.my-lg-0 {
        margin-top: 0 !important;
    }

    .mr-lg-0,
.mx-lg-0 {
        margin-right: 0 !important;
    }

    .mb-lg-0,
.my-lg-0 {
        margin-bottom: 0 !important;
    }

    .ml-lg-0,
.mx-lg-0 {
        margin-left: 0 !important;
    }

    .m-lg-1 {
        margin: 0.25rem !important;
    }

    .mt-lg-1,
.my-lg-1 {
        margin-top: 0.25rem !important;
    }

    .mr-lg-1,
.mx-lg-1 {
        margin-right: 0.25rem !important;
    }

    .mb-lg-1,
.my-lg-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-lg-1,
.mx-lg-1 {
        margin-left: 0.25rem !important;
    }

    .m-lg-2 {
        margin: 0.5rem !important;
    }

    .mt-lg-2,
.my-lg-2 {
        margin-top: 0.5rem !important;
    }

    .mr-lg-2,
.mx-lg-2 {
        margin-right: 0.5rem !important;
    }

    .mb-lg-2,
.my-lg-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-lg-2,
.mx-lg-2 {
        margin-left: 0.5rem !important;
    }

    .m-lg-3 {
        margin: 1rem !important;
    }

    .mt-lg-3,
.my-lg-3 {
        margin-top: 1rem !important;
    }

    .mr-lg-3,
.mx-lg-3 {
        margin-right: 1rem !important;
    }

    .mb-lg-3,
.my-lg-3 {
        margin-bottom: 1rem !important;
    }

    .ml-lg-3,
.mx-lg-3 {
        margin-left: 1rem !important;
    }

    .m-lg-4 {
        margin: 1.5rem !important;
    }

    .mt-lg-4,
.my-lg-4 {
        margin-top: 1.5rem !important;
    }

    .mr-lg-4,
.mx-lg-4 {
        margin-right: 1.5rem !important;
    }

    .mb-lg-4,
.my-lg-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-lg-4,
.mx-lg-4 {
        margin-left: 1.5rem !important;
    }

    .m-lg-5 {
        margin: 3rem !important;
    }

    .mt-lg-5,
.my-lg-5 {
        margin-top: 3rem !important;
    }

    .mr-lg-5,
.mx-lg-5 {
        margin-right: 3rem !important;
    }

    .mb-lg-5,
.my-lg-5 {
        margin-bottom: 3rem !important;
    }

    .ml-lg-5,
.mx-lg-5 {
        margin-left: 3rem !important;
    }

    .p-lg-0 {
        padding: 0 !important;
    }

    .pt-lg-0,
.py-lg-0 {
        padding-top: 0 !important;
    }

    .pr-lg-0,
.px-lg-0 {
        padding-right: 0 !important;
    }

    .pb-lg-0,
.py-lg-0 {
        padding-bottom: 0 !important;
    }

    .pl-lg-0,
.px-lg-0 {
        padding-left: 0 !important;
    }

    .p-lg-1 {
        padding: 0.25rem !important;
    }

    .pt-lg-1,
.py-lg-1 {
        padding-top: 0.25rem !important;
    }

    .pr-lg-1,
.px-lg-1 {
        padding-right: 0.25rem !important;
    }

    .pb-lg-1,
.py-lg-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-lg-1,
.px-lg-1 {
        padding-left: 0.25rem !important;
    }

    .p-lg-2 {
        padding: 0.5rem !important;
    }

    .pt-lg-2,
.py-lg-2 {
        padding-top: 0.5rem !important;
    }

    .pr-lg-2,
.px-lg-2 {
        padding-right: 0.5rem !important;
    }

    .pb-lg-2,
.py-lg-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-lg-2,
.px-lg-2 {
        padding-left: 0.5rem !important;
    }

    .p-lg-3 {
        padding: 1rem !important;
    }

    .pt-lg-3,
.py-lg-3 {
        padding-top: 1rem !important;
    }

    .pr-lg-3,
.px-lg-3 {
        padding-right: 1rem !important;
    }

    .pb-lg-3,
.py-lg-3 {
        padding-bottom: 1rem !important;
    }

    .pl-lg-3,
.px-lg-3 {
        padding-left: 1rem !important;
    }

    .p-lg-4 {
        padding: 1.5rem !important;
    }

    .pt-lg-4,
.py-lg-4 {
        padding-top: 1.5rem !important;
    }

    .pr-lg-4,
.px-lg-4 {
        padding-right: 1.5rem !important;
    }

    .pb-lg-4,
.py-lg-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-lg-4,
.px-lg-4 {
        padding-left: 1.5rem !important;
    }

    .p-lg-5 {
        padding: 3rem !important;
    }

    .pt-lg-5,
.py-lg-5 {
        padding-top: 3rem !important;
    }

    .pr-lg-5,
.px-lg-5 {
        padding-right: 3rem !important;
    }

    .pb-lg-5,
.py-lg-5 {
        padding-bottom: 3rem !important;
    }

    .pl-lg-5,
.px-lg-5 {
        padding-left: 3rem !important;
    }

    .m-lg-n1 {
        margin: -0.25rem !important;
    }

    .mt-lg-n1,
.my-lg-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-lg-n1,
.mx-lg-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-lg-n1,
.my-lg-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-lg-n1,
.mx-lg-n1 {
        margin-left: -0.25rem !important;
    }

    .m-lg-n2 {
        margin: -0.5rem !important;
    }

    .mt-lg-n2,
.my-lg-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-lg-n2,
.mx-lg-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-lg-n2,
.my-lg-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-lg-n2,
.mx-lg-n2 {
        margin-left: -0.5rem !important;
    }

    .m-lg-n3 {
        margin: -1rem !important;
    }

    .mt-lg-n3,
.my-lg-n3 {
        margin-top: -1rem !important;
    }

    .mr-lg-n3,
.mx-lg-n3 {
        margin-right: -1rem !important;
    }

    .mb-lg-n3,
.my-lg-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-lg-n3,
.mx-lg-n3 {
        margin-left: -1rem !important;
    }

    .m-lg-n4 {
        margin: -1.5rem !important;
    }

    .mt-lg-n4,
.my-lg-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-lg-n4,
.mx-lg-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-lg-n4,
.my-lg-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-lg-n4,
.mx-lg-n4 {
        margin-left: -1.5rem !important;
    }

    .m-lg-n5 {
        margin: -3rem !important;
    }

    .mt-lg-n5,
.my-lg-n5 {
        margin-top: -3rem !important;
    }

    .mr-lg-n5,
.mx-lg-n5 {
        margin-right: -3rem !important;
    }

    .mb-lg-n5,
.my-lg-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-lg-n5,
.mx-lg-n5 {
        margin-left: -3rem !important;
    }

    .m-lg-auto {
        margin: auto !important;
    }

    .mt-lg-auto,
.my-lg-auto {
        margin-top: auto !important;
    }

    .mr-lg-auto,
.mx-lg-auto {
        margin-right: auto !important;
    }

    .mb-lg-auto,
.my-lg-auto {
        margin-bottom: auto !important;
    }

    .ml-lg-auto,
.mx-lg-auto {
        margin-left: auto !important;
    }
}
@media (min-width: 1200px) {
    .m-xl-0 {
        margin: 0 !important;
    }

    .mt-xl-0,
.my-xl-0 {
        margin-top: 0 !important;
    }

    .mr-xl-0,
.mx-xl-0 {
        margin-right: 0 !important;
    }

    .mb-xl-0,
.my-xl-0 {
        margin-bottom: 0 !important;
    }

    .ml-xl-0,
.mx-xl-0 {
        margin-left: 0 !important;
    }

    .m-xl-1 {
        margin: 0.25rem !important;
    }

    .mt-xl-1,
.my-xl-1 {
        margin-top: 0.25rem !important;
    }

    .mr-xl-1,
.mx-xl-1 {
        margin-right: 0.25rem !important;
    }

    .mb-xl-1,
.my-xl-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-xl-1,
.mx-xl-1 {
        margin-left: 0.25rem !important;
    }

    .m-xl-2 {
        margin: 0.5rem !important;
    }

    .mt-xl-2,
.my-xl-2 {
        margin-top: 0.5rem !important;
    }

    .mr-xl-2,
.mx-xl-2 {
        margin-right: 0.5rem !important;
    }

    .mb-xl-2,
.my-xl-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-xl-2,
.mx-xl-2 {
        margin-left: 0.5rem !important;
    }

    .m-xl-3 {
        margin: 1rem !important;
    }

    .mt-xl-3,
.my-xl-3 {
        margin-top: 1rem !important;
    }

    .mr-xl-3,
.mx-xl-3 {
        margin-right: 1rem !important;
    }

    .mb-xl-3,
.my-xl-3 {
        margin-bottom: 1rem !important;
    }

    .ml-xl-3,
.mx-xl-3 {
        margin-left: 1rem !important;
    }

    .m-xl-4 {
        margin: 1.5rem !important;
    }

    .mt-xl-4,
.my-xl-4 {
        margin-top: 1.5rem !important;
    }

    .mr-xl-4,
.mx-xl-4 {
        margin-right: 1.5rem !important;
    }

    .mb-xl-4,
.my-xl-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-xl-4,
.mx-xl-4 {
        margin-left: 1.5rem !important;
    }

    .m-xl-5 {
        margin: 3rem !important;
    }

    .mt-xl-5,
.my-xl-5 {
        margin-top: 3rem !important;
    }

    .mr-xl-5,
.mx-xl-5 {
        margin-right: 3rem !important;
    }

    .mb-xl-5,
.my-xl-5 {
        margin-bottom: 3rem !important;
    }

    .ml-xl-5,
.mx-xl-5 {
        margin-left: 3rem !important;
    }

    .p-xl-0 {
        padding: 0 !important;
    }

    .pt-xl-0,
.py-xl-0 {
        padding-top: 0 !important;
    }

    .pr-xl-0,
.px-xl-0 {
        padding-right: 0 !important;
    }

    .pb-xl-0,
.py-xl-0 {
        padding-bottom: 0 !important;
    }

    .pl-xl-0,
.px-xl-0 {
        padding-left: 0 !important;
    }

    .p-xl-1 {
        padding: 0.25rem !important;
    }

    .pt-xl-1,
.py-xl-1 {
        padding-top: 0.25rem !important;
    }

    .pr-xl-1,
.px-xl-1 {
        padding-right: 0.25rem !important;
    }

    .pb-xl-1,
.py-xl-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-xl-1,
.px-xl-1 {
        padding-left: 0.25rem !important;
    }

    .p-xl-2 {
        padding: 0.5rem !important;
    }

    .pt-xl-2,
.py-xl-2 {
        padding-top: 0.5rem !important;
    }

    .pr-xl-2,
.px-xl-2 {
        padding-right: 0.5rem !important;
    }

    .pb-xl-2,
.py-xl-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-xl-2,
.px-xl-2 {
        padding-left: 0.5rem !important;
    }

    .p-xl-3 {
        padding: 1rem !important;
    }

    .pt-xl-3,
.py-xl-3 {
        padding-top: 1rem !important;
    }

    .pr-xl-3,
.px-xl-3 {
        padding-right: 1rem !important;
    }

    .pb-xl-3,
.py-xl-3 {
        padding-bottom: 1rem !important;
    }

    .pl-xl-3,
.px-xl-3 {
        padding-left: 1rem !important;
    }

    .p-xl-4 {
        padding: 1.5rem !important;
    }

    .pt-xl-4,
.py-xl-4 {
        padding-top: 1.5rem !important;
    }

    .pr-xl-4,
.px-xl-4 {
        padding-right: 1.5rem !important;
    }

    .pb-xl-4,
.py-xl-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-xl-4,
.px-xl-4 {
        padding-left: 1.5rem !important;
    }

    .p-xl-5 {
        padding: 3rem !important;
    }

    .pt-xl-5,
.py-xl-5 {
        padding-top: 3rem !important;
    }

    .pr-xl-5,
.px-xl-5 {
        padding-right: 3rem !important;
    }

    .pb-xl-5,
.py-xl-5 {
        padding-bottom: 3rem !important;
    }

    .pl-xl-5,
.px-xl-5 {
        padding-left: 3rem !important;
    }

    .m-xl-n1 {
        margin: -0.25rem !important;
    }

    .mt-xl-n1,
.my-xl-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-xl-n1,
.mx-xl-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-xl-n1,
.my-xl-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-xl-n1,
.mx-xl-n1 {
        margin-left: -0.25rem !important;
    }

    .m-xl-n2 {
        margin: -0.5rem !important;
    }

    .mt-xl-n2,
.my-xl-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-xl-n2,
.mx-xl-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-xl-n2,
.my-xl-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-xl-n2,
.mx-xl-n2 {
        margin-left: -0.5rem !important;
    }

    .m-xl-n3 {
        margin: -1rem !important;
    }

    .mt-xl-n3,
.my-xl-n3 {
        margin-top: -1rem !important;
    }

    .mr-xl-n3,
.mx-xl-n3 {
        margin-right: -1rem !important;
    }

    .mb-xl-n3,
.my-xl-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-xl-n3,
.mx-xl-n3 {
        margin-left: -1rem !important;
    }

    .m-xl-n4 {
        margin: -1.5rem !important;
    }

    .mt-xl-n4,
.my-xl-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-xl-n4,
.mx-xl-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-xl-n4,
.my-xl-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-xl-n4,
.mx-xl-n4 {
        margin-left: -1.5rem !important;
    }

    .m-xl-n5 {
        margin: -3rem !important;
    }

    .mt-xl-n5,
.my-xl-n5 {
        margin-top: -3rem !important;
    }

    .mr-xl-n5,
.mx-xl-n5 {
        margin-right: -3rem !important;
    }

    .mb-xl-n5,
.my-xl-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-xl-n5,
.mx-xl-n5 {
        margin-left: -3rem !important;
    }

    .m-xl-auto {
        margin: auto !important;
    }

    .mt-xl-auto,
.my-xl-auto {
        margin-top: auto !important;
    }

    .mr-xl-auto,
.mx-xl-auto {
        margin-right: auto !important;
    }

    .mb-xl-auto,
.my-xl-auto {
        margin-bottom: auto !important;
    }

    .ml-xl-auto,
.mx-xl-auto {
        margin-left: auto !important;
    }
}
html {
    font-size: 62.5%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

body {
    margin: 0;
    font-size: 1em;
    line-height: 1.4;
}

*,
::before,
::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

::-moz-selection {
    background: #241a17;
    color: #fff;
    text-shadow: none;
}

::selection {
    background: #241a17;
    color: #fff;
    text-shadow: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
    display: block;
}

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
}

audio:not([controls]) {
    display: none;
}

[hidden] {
    display: none;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    display: inherit;
    font-size: inherit;
    font-weight: inherit;
}

a {
    cursor: pointer;
    text-decoration: none;
}
a:hover, a:active, a:focus {
    outline: none;
}

bbr[title] {
    border-bottom: 1px dotted;
}

b,
strong {
    font-weight: bold;
}

blockquote {
    margin: 1em 40px;
}

dfn {
    font-style: italic;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #cbc1b3;
    margin: 1.5em 0;
    padding: 0;
}

ins {
    background: #ff9;
    color: #000;
    text-decoration: none;
}

mark {
    background: #ff0;
    color: #000;
    font-style: italic;
    font-weight: bold;
}

pre,
code,
kbd,
samp {
    font-family: monospace, serif;
    _font-family: "courier new", monospace;
    font-size: 1em;
}

pre {
    white-space: pre-wrap;
    word-wrap: break-word;
}

q {
    quotes: none;
}

q:before,
q:after {
    content: "";
}

small {
    font-size: 85%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

ul,
ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

dd {
    margin: 0 0 0 40px;
}

img {
    border: 0;
    -ms-interpolation-mode: bicubic;
    vertical-align: middle;
}

svg:not(:root) {
    overflow: hidden;
}

figure {
    margin: 0;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td {
    vertical-align: top;
}

/* form reset */
form {
    margin: 0;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

label {
    cursor: pointer;
}

legend {
    border: 0;
    *margin-left: -7px;
    padding: 0;
    white-space: normal;
}

button,
input,
select,
textarea {
    font-size: 100%;
    margin: 0;
    vertical-align: baseline;
    *vertical-align: middle;
    outline: none;
}

button,
input {
    line-height: normal;
}

button,
input[type=button],
input[type=reset],
input[type=submit] {
    cursor: pointer;
    -webkit-appearance: button;
    *overflow: visible;
}

button[disabled],
input[disabled] {
    cursor: default;
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0;
    *width: 13px;
    *height: 13px;
}

input[type=search] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

input:invalid,
textarea:invalid {
    background-color: #f0dddd;
}

@media print {
    * {
        background: transparent !important;
        color: black !important;
        box-shadow: none !important;
        text-shadow: none !important;
        filter: none !important;
        -ms-filter: none !important;
    }

    /* Black prints faster: h5bp.com/s */
    a,
a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    .ir a:after,
a[href^="javascript:"]:after,
a[href^="#"]:after {
        content: "";
    }

    /* Don't show links for images,
    or javascript/internal links */
    pre,
blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    /* h5bp.com/t */
    tr,
img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    @page {
        margin: 0.5cm;
    }
    p,
h2,
h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
h3 {
        page-break-after: avoid;
    }
}
@font-face {
    font-family: "AribauGroteskBlk";
    src: url(./../../fonts/AribauGrotesk-Blk.woff2) format("woff2"), url(./../../fonts/AribauGrotesk-Blk.woff) format("woff");
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family: "AribauGroteskBd";
    src: url(./../../fonts/AribauGrotesk-Bd.woff2) format("woff2"), url(./../../fonts/AribauGrotesk-Bd.woff) format("woff");
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family: "AribauGroteskMd";
    src: url(./../../fonts/AribauGrotesk-Md.woff2) format("woff2"), url(./../../fonts/AribauGrotesk-Md.woff) format("woff");
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family: "AribauGroteskRg";
    src: url(./../../fonts/AribauGrotesk-Rg.woff2) format("woff2"), url(./../../fonts/AribauGrotesk-Rg.woff) format("woff");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family: "AribauGroteskLt";
    src: url(./../../fonts/AribauGrotesk-Lt.woff2) format("woff2"), url(./../../fonts/AribauGrotesk-Lt.woff) format("woff");
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family: "AribauGroteskXlt";
    src: url(./../../fonts/AribauGrotesk-Xlt.woff2) format("woff2"), url(./../../fonts/AribauGrotesk-Xlt.woff) format("woff");
    font-weight: 100;
    font-style: normal;
    font-stretch: normal;
}
.col-wd-1,
.col-wd-2,
.col-wd-3,
.col-wd-4,
.col-wd-5,
.col-wd-6,
.col-wd-7,
.col-wd-8,
.col-wd-9,
.col-wd-10,
.col-wd-11,
.col-wd-12,
.col-wd,
.col-wd-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 1400px) {
    .col-wd {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-wd-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-wd-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .col-wd-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-wd-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-wd-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-wd-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-wd-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-wd-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .col-wd-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-wd-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-wd-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .col-wd-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .col-wd-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-wd-first {
        -ms-flex-order: -1;
        order: -1;
    }

    .order-wd-last {
        -ms-flex-order: 13;
        order: 13;
    }

    .order-wd-0 {
        -ms-flex-order: 0;
        order: 0;
    }

    .order-wd-1 {
        -ms-flex-order: 1;
        order: 1;
    }

    .order-wd-2 {
        -ms-flex-order: 2;
        order: 2;
    }

    .order-wd-3 {
        -ms-flex-order: 3;
        order: 3;
    }

    .order-wd-4 {
        -ms-flex-order: 4;
        order: 4;
    }

    .order-wd-5 {
        -ms-flex-order: 5;
        order: 5;
    }

    .order-wd-6 {
        -ms-flex-order: 6;
        order: 6;
    }

    .order-wd-7 {
        -ms-flex-order: 7;
        order: 7;
    }

    .order-wd-8 {
        -ms-flex-order: 8;
        order: 8;
    }

    .order-wd-9 {
        -ms-flex-order: 9;
        order: 9;
    }

    .order-wd-10 {
        -ms-flex-order: 10;
        order: 10;
    }

    .order-wd-11 {
        -ms-flex-order: 11;
        order: 11;
    }

    .order-wd-12 {
        -ms-flex-order: 12;
        order: 12;
    }

    .offset-wd-0 {
        margin-left: 0;
    }

    .offset-wd-1 {
        margin-left: 8.333333%;
    }

    .offset-wd-2 {
        margin-left: 16.666667%;
    }

    .offset-wd-3 {
        margin-left: 25%;
    }

    .offset-wd-4 {
        margin-left: 33.333333%;
    }

    .offset-wd-5 {
        margin-left: 41.666667%;
    }

    .offset-wd-6 {
        margin-left: 50%;
    }

    .offset-wd-7 {
        margin-left: 58.333333%;
    }

    .offset-wd-8 {
        margin-left: 66.666667%;
    }

    .offset-wd-9 {
        margin-left: 75%;
    }

    .offset-wd-10 {
        margin-left: 83.333333%;
    }

    .offset-wd-11 {
        margin-left: 91.666667%;
    }

    .d-wd-none {
        display: none !important;
    }

    .d-wd-inline {
        display: inline !important;
    }

    .d-wd-inline-block {
        display: inline-block !important;
    }

    .d-wd-block {
        display: block !important;
    }

    .d-wd-table {
        display: table !important;
    }

    .d-wd-table-row {
        display: table-row !important;
    }

    .d-wd-table-cell {
        display: table-cell !important;
    }

    .d-wd-flex {
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-wd-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }

    .flex-wd-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    .flex-wd-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    .flex-wd-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }

    .flex-wd-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }

    .flex-wd-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }

    .flex-wd-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }

    .flex-wd-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }

    .flex-wd-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }

    .flex-wd-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }

    .flex-wd-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }

    .flex-wd-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }

    .flex-wd-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }

    .justify-content-wd-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-wd-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-wd-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-wd-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-wd-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }

    .align-items-wd-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .align-items-wd-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-wd-center {
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .align-items-wd-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }

    .align-items-wd-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    .align-content-wd-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }

    .align-content-wd-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }

    .align-content-wd-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }

    .align-content-wd-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }

    .align-content-wd-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }

    .align-content-wd-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }

    .align-self-wd-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }

    .align-self-wd-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }

    .align-self-wd-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .align-self-wd-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }

    .align-self-wd-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }

    .align-self-wd-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }

    .m-wd-0 {
        margin: 0 !important;
    }

    .mt-wd-0,
.my-wd-0 {
        margin-top: 0 !important;
    }

    .mr-wd-0,
.mx-wd-0 {
        margin-right: 0 !important;
    }

    .mb-wd-0,
.my-wd-0 {
        margin-bottom: 0 !important;
    }

    .ml-wd-0,
.mx-wd-0 {
        margin-left: 0 !important;
    }

    .m-wd-1 {
        margin: 0.25rem !important;
    }

    .mt-wd-1,
.my-wd-1 {
        margin-top: 0.25rem !important;
    }

    .mr-wd-1,
.mx-wd-1 {
        margin-right: 0.25rem !important;
    }

    .mb-wd-1,
.my-wd-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-wd-1,
.mx-wd-1 {
        margin-left: 0.25rem !important;
    }

    .m-wd-2 {
        margin: 0.5rem !important;
    }

    .mt-wd-2,
.my-wd-2 {
        margin-top: 0.5rem !important;
    }

    .mr-wd-2,
.mx-wd-2 {
        margin-right: 0.5rem !important;
    }

    .mb-wd-2,
.my-wd-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-wd-2,
.mx-wd-2 {
        margin-left: 0.5rem !important;
    }

    .m-wd-3 {
        margin: 1rem !important;
    }

    .mt-wd-3,
.my-wd-3 {
        margin-top: 1rem !important;
    }

    .mr-wd-3,
.mx-wd-3 {
        margin-right: 1rem !important;
    }

    .mb-wd-3,
.my-wd-3 {
        margin-bottom: 1rem !important;
    }

    .ml-wd-3,
.mx-wd-3 {
        margin-left: 1rem !important;
    }

    .m-wd-4 {
        margin: 1.5rem !important;
    }

    .mt-wd-4,
.my-wd-4 {
        margin-top: 1.5rem !important;
    }

    .mr-wd-4,
.mx-wd-4 {
        margin-right: 1.5rem !important;
    }

    .mb-wd-4,
.my-wd-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-wd-4,
.mx-wd-4 {
        margin-left: 1.5rem !important;
    }

    .m-wd-5 {
        margin: 3rem !important;
    }

    .mt-wd-5,
.my-wd-5 {
        margin-top: 3rem !important;
    }

    .mr-wd-5,
.mx-wd-5 {
        margin-right: 3rem !important;
    }

    .mb-wd-5,
.my-wd-5 {
        margin-bottom: 3rem !important;
    }

    .ml-wd-5,
.mx-wd-5 {
        margin-left: 3rem !important;
    }

    .p-wd-0 {
        padding: 0 !important;
    }

    .pt-wd-0,
.py-wd-0 {
        padding-top: 0 !important;
    }

    .pr-wd-0,
.px-wd-0 {
        padding-right: 0 !important;
    }

    .pb-wd-0,
.py-wd-0 {
        padding-bottom: 0 !important;
    }

    .pl-wd-0,
.px-wd-0 {
        padding-left: 0 !important;
    }

    .p-wd-1 {
        padding: 0.25rem !important;
    }

    .pt-wd-1,
.py-wd-1 {
        padding-top: 0.25rem !important;
    }

    .pr-wd-1,
.px-wd-1 {
        padding-right: 0.25rem !important;
    }

    .pb-wd-1,
.py-wd-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-wd-1,
.px-wd-1 {
        padding-left: 0.25rem !important;
    }

    .p-wd-2 {
        padding: 0.5rem !important;
    }

    .pt-wd-2,
.py-wd-2 {
        padding-top: 0.5rem !important;
    }

    .pr-wd-2,
.px-wd-2 {
        padding-right: 0.5rem !important;
    }

    .pb-wd-2,
.py-wd-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-wd-2,
.px-wd-2 {
        padding-left: 0.5rem !important;
    }

    .p-wd-3 {
        padding: 1rem !important;
    }

    .pt-wd-3,
.py-wd-3 {
        padding-top: 1rem !important;
    }

    .pr-wd-3,
.px-wd-3 {
        padding-right: 1rem !important;
    }

    .pb-wd-3,
.py-wd-3 {
        padding-bottom: 1rem !important;
    }

    .pl-wd-3,
.px-wd-3 {
        padding-left: 1rem !important;
    }

    .p-wd-4 {
        padding: 1.5rem !important;
    }

    .pt-wd-4,
.py-wd-4 {
        padding-top: 1.5rem !important;
    }

    .pr-wd-4,
.px-wd-4 {
        padding-right: 1.5rem !important;
    }

    .pb-wd-4,
.py-wd-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-wd-4,
.px-wd-4 {
        padding-left: 1.5rem !important;
    }

    .p-wd-5 {
        padding: 3rem !important;
    }

    .pt-wd-5,
.py-wd-5 {
        padding-top: 3rem !important;
    }

    .pr-wd-5,
.px-wd-5 {
        padding-right: 3rem !important;
    }

    .pb-wd-5,
.py-wd-5 {
        padding-bottom: 3rem !important;
    }

    .pl-wd-5,
.px-wd-5 {
        padding-left: 3rem !important;
    }

    .m-wd-n1 {
        margin: -0.25rem !important;
    }

    .mt-wd-n1,
.my-wd-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-wd-n1,
.mx-wd-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-wd-n1,
.my-wd-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-wd-n1,
.mx-wd-n1 {
        margin-left: -0.25rem !important;
    }

    .m-wd-n2 {
        margin: -0.5rem !important;
    }

    .mt-wd-n2,
.my-wd-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-wd-n2,
.mx-wd-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-wd-n2,
.my-wd-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-wd-n2,
.mx-wd-n2 {
        margin-left: -0.5rem !important;
    }

    .m-wd-n3 {
        margin: -1rem !important;
    }

    .mt-wd-n3,
.my-wd-n3 {
        margin-top: -1rem !important;
    }

    .mr-wd-n3,
.mx-wd-n3 {
        margin-right: -1rem !important;
    }

    .mb-wd-n3,
.my-wd-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-wd-n3,
.mx-wd-n3 {
        margin-left: -1rem !important;
    }

    .m-wd-n4 {
        margin: -1.5rem !important;
    }

    .mt-wd-n4,
.my-wd-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-wd-n4,
.mx-wd-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-wd-n4,
.my-wd-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-wd-n4,
.mx-wd-n4 {
        margin-left: -1.5rem !important;
    }

    .m-wd-n5 {
        margin: -3rem !important;
    }

    .mt-wd-n5,
.my-wd-n5 {
        margin-top: -3rem !important;
    }

    .mr-wd-n5,
.mx-wd-n5 {
        margin-right: -3rem !important;
    }

    .mb-wd-n5,
.my-wd-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-wd-n5,
.mx-wd-n5 {
        margin-left: -3rem !important;
    }

    .m-wd-auto {
        margin: auto !important;
    }

    .mt-wd-auto,
.my-wd-auto {
        margin-top: auto !important;
    }

    .mr-wd-auto,
.mx-wd-auto {
        margin-right: auto !important;
    }

    .mb-wd-auto,
.my-wd-auto {
        margin-bottom: auto !important;
    }

    .ml-wd-auto,
.mx-wd-auto {
        margin-left: auto !important;
    }
}
.btn {
    display: inline-block;
    padding: 11px 20px;
    font-family: "AribauGroteskRg", Verdana, sans-serif;
    font-size: 1.6rem;
    color: #fff;
    text-align: left;
    border-radius: 24px;
    border: 1px solid #1c24ea;
    background-color: #1c24ea;
}
.btn--ok {
    padding-right: 38px;
    background: #1c24ea url("./../../img/common/pics/ok.png") no-repeat right 20px center;
    background-size: 14px 17px;
}
.btn--prev {
    padding-left: 38px;
    background: #1c24ea url("./../../img/common/pics/prev_white.svg") no-repeat left 20px center;
    background-size: 7px 14px;
}
.btn--next {
    padding-right: 38px;
    background: #1c24ea url("./../../img/common/pics/next_white.svg") no-repeat right 20px center;
    background-size: 7px 14px;
}
.btn--outline {
    color: #1c24ea !important;
    background-color: #fff;
}
.btn--delete {
    background-color: #ee5340;
    border-color: #ee5340;
}
.btn--deletePic {
    width: 20px;
    height: 20px;
    padding: 0;
    border: 0;
    background: transparent url("./../../img/common/pics/cross.svg") no-repeat 0 center;
    background-size: 20px 88px;
}
.btn--plus {
    display: flex;
    align-items: center;
    height: 44px;
    padding-left: 55px;
    color: #9E9EA9;
    background: transparent url("./../../img/common/pics/plus_small.svg") no-repeat 0 0;
    background-size: 44px 44px;
    border: 0;
}
.btn--plus--small {
    height: 30px;
    font-size: 1.4rem;
    padding-left: 41px;
    background-size: 30px 30px;
}
.btn--addContact {
    display: flex;
    align-items: center;
    height: 44px;
    padding-left: 55px;
    background: transparent url("./../../img/common/pics/plus_blue.svg") no-repeat left 1em center;
    background-size: 21px auto;
}

.link {
    font-family: "AribauGroteskRg", Verdana, sans-serif;
    font-size: 1.6rem;
    color: #1c24ea;
    text-align: left;
}
.link--picto {
    padding-left: 35px;
    background-color: transparent;
    background-position: 0 center;
    background-repeat: no-repeat;
    background-size: 20px;
}
.link--picto.basket {
    background-image: url("./../../img/common/pics/basket.svg");
}
.link--picto.envelope {
    background-image: url("./../../img/common/pics/envelope.svg");
}
.link--picto.pen {
    background-image: url("./../../img/common/pics/pen.svg");
}
.link--picto.phone {
    background-image: url("./../../img/common/pics/phone.svg");
}
.link--picto.trashcan {
    background-image: url("./../../img/common/pics/trashcan.svg");
}

button.link {
    border: 0;
}

.loading {
    position: fixed;
    z-index: 150;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
}
.loading.hidden {
    display: none;
}

.folding-cube {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 40px;
    height: 40px;
}
.folding-cube .cube {
    position: relative;
    float: left;
    width: 50%;
    height: 50%;
    transform: scale(1.1);
}
.folding-cube .cube:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #ee5340;
    animation: foldCubeAngle 2.4s infinite linear both;
    transform-origin: 100% 100%;
}
.folding-cube .cube2 {
    transform: scale(1.1) rotateZ(90deg);
}
.folding-cube .cube2:before {
    animation-delay: 0.3s;
}
.folding-cube .cube3 {
    transform: scale(1.1) rotateZ(180deg);
}
.folding-cube .cube3:before {
    animation-delay: 0.6s;
}
.folding-cube .cube4 {
    transform: scale(1.1) rotateZ(270deg);
}
.folding-cube .cube4:before {
    animation-delay: 0.9s;
}

@-webkit-keyframes foldCubeAngle {
    0%, 10% {
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }
    25%, 75% {
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }
    90%, 100% {
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}
@keyframes foldCubeAngle {
    0%, 10% {
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }
    25%, 75% {
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }
    90%, 100% {
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}
.pagination {
    margin: 25px 0;
}
.pagination ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    text-align: center;
}
.pagination li {
    display: flex;
    align-items: center;
    justify-content: center;
}
.pagination li + li {
    margin-left: 10px;
}
.pagination li a {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 20px 8px;
}
.pagination__first a {
    background-image: url("./../../img/common/pics/first_gray.svg");
}
.pagination__prev a {
    background-image: url("./../../img/common/pics/prev_gray.svg");
}
.pagination__next a {
    background-image: url("./../../img/common/pics/next_gray.svg");
}
.pagination__last a {
    background-image: url("./../../img/common/pics/last_gray.svg");
}

/* popin */
@keyframes popin-open {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes popin-close {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.popin {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 91px;
    z-index: 20000;
    width: 100%;
    height: 100vh;
    background: rgba(56, 59, 64, 0.7);
}
.popin.open {
    display: flex;
    animation: popin-open 0.3s ease both;
}
.popin.close {
    animation: popin-close 0.3s ease both;
}
.popin__inner {
    position: relative;
}
.popin__box {
    height: 100%;
    padding: 50px 90px;
    overflow-y: auto;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
}
.popin__box--small {
    width: 480px;
}
.popin__title {
    font-family: "AribauGroteskBd", Verdana, sans-serif;
    font-size: 3.6rem;
    color: #ee5340;
    text-align: center;
}
.popin__message {
    font-size: 2rem;
    text-align: center;
}
.popin__close {
    display: flex;
    margin: auto;
    cursor: pointer;
}
.popin__close--default {
    position: absolute;
    top: 50px;
    right: 90px;
    z-index: 10;
    display: block;
    width: 20px;
    height: 20px;
    background: transparent url("./../../img/common/pics/cross.svg") no-repeat 0 center;
    background-size: 20px 88px;
}

.tableStriped {
    width: 100%;
    font-size: 1.4rem;
    border-bottom: 1px solid #F4F5F7;
}
.tableStriped thead {
    color: #383b41;
    text-align: left;
    background-color: #F4F5F7;
}
.tableStriped thead th {
    padding: 20px 30px;
    font-size: 1.2rem;
    color: #383b41;
}
.tableStriped th, .tableStriped td {
    padding: 10px 30px;
    color: #A6ADB9;
    font-weight: normal;
}
.tableStriped th + th, .tableStriped th + td, .tableStriped td + th, .tableStriped td + td {
    border-left: 1px solid #DFE1E5;
}
.tableStriped tbody tr:nth-of-type(even) {
    background-color: #F4F5F7;
}

.mainWrapper {
    font-size: 1.2rem;
}
.mainWrapper .mainWrapper__inner {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    min-height: calc(100vh);
}
@media (min-width: 768px) {
    .mainWrapper .mainWrapper__inner {
        margin-top: 0;
        margin-left: 91px;
    }
}

.mainContent {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
}
.mainContent__header__inner {
    margin: 50px 28px 20px;
}
@media (min-width: 992px) {
    .mainContent__header__inner {
        display: flex;
        align-items: center;
        margin: 50px 8.333333% 30px;
    }
}
.mainContent__header__formDelete {
    float: right;
}
@media (min-width: 992px) {
    .mainContent__header__formDelete {
        margin-left: auto;
    }
}
.mainContent__inner {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    overflow-x: hidden;
}

.appNav {
    width: 100%;
    padding: 0 28px;
    background-color: #fff;
    border-bottom: 1px solid #f1f2f3;
}
@media (min-width: 768px) {
    .appNav {
        height: 70px;
    }
}
.appNav__toggler {
    position: relative;
    z-index: 2;
    width: 30px;
    height: 30px;
    padding: 0;
    margin-top: 5px;
    border: 0;
    background-color: transparent;
}
@media (min-width: 768px) {
    .appNav__toggler {
        display: none;
    }
}
.appNav__toggler span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 30px;
    height: 2px;
    background-color: #1c24ea;
    pointer-events: none;
    transition: background-color 0.2s ease;
    will-change: background-color;
}
.appNav__toggler span:before, .appNav__toggler span:after {
    position: absolute;
    left: 0;
    content: "";
    display: block;
    width: 30px;
    height: 2px;
    background-color: #1c24ea;
    will-change: top, bottom, transform;
}
.appNav__toggler span::before {
    top: -8px;
    transition: top 0.2s ease, transform 0.2s ease;
}
.appNav__toggler span:after {
    bottom: -8px;
    transition: bottom 0.2s ease, transform 0.2s ease;
}
.appNav__toggler.open span {
    background: none;
}
.appNav__toggler.open span:before {
    top: 0;
    transform: rotate(45deg);
}
.appNav__toggler.open span:after {
    bottom: 0;
    transform: rotate(-45deg);
}
.appNav__list {
    display: none;
    font-size: 1.4rem;
}
@media (min-width: 768px) {
    .appNav__list {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: 1.4rem;
    }
    .appNav__list li + li {
        margin-left: 40px;
    }
    .appNav__list li + li.appNav__list__rightItem {
        margin-left: auto;
    }
}
@media (min-width: 992px) {
    .appNav__list {
        font-size: 2rem;
    }
}
.appNav__list a {
    color: #383b41;
}
.appNav__list a.active {
    display: inline-block;
    color: #1c24ea;
    border-bottom: 1px solid #1c24ea;
}
@media (min-width: 768px) {
    .appNav__list a.active {
        position: relative;
        line-height: 70px;
        border-bottom: 0;
    }
    .appNav__list a.active:after {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 4px;
        content: "";
        background-color: #1c24ea;
    }
}
.appNav.showMenu {
    height: auto;
    padding-bottom: 15px;
}
.appNav.showMenu .appNav__list {
    display: block;
}

html {
    font-size: 62.5%;
}

html,
body {
    height: 100%;
}

body {
    font-family: "AribauGroteskRg", Verdana, sans-serif;
    font-size: 1em;
    color: #000;
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.wrapper {
    font-size: 1.2rem;
}

a {
    color: #1c24ea;
}

.tl1 {
    font-family: "AribauGroteskLt", Verdana, sans-serif;
    font-size: 4rem;
    color: #ee5340;
    letter-spacing: 0;
}
.tl1--center {
    text-align: center;
}
@media (min-width: 768px) {
    .tl1 {
        font-size: 6rem;
    }
}
@media (min-width: 992px) {
    .tl1 {
        font-size: 8rem;
    }
}

.tl2 {
    font-family: "AribauGroteskLt", Verdana, sans-serif;
    font-size: 2rem;
    color: #ee5340;
    letter-spacing: 0;
}
.tl2--center {
    text-align: center;
}
@media (min-width: 768px) {
    .tl2 {
        font-size: 4rem;
    }
}
@media (min-width: 992px) {
    .tl2 {
        font-size: 6rem;
    }
}

.tl3 {
    font-family: "AribauGroteskBd", Verdana, sans-serif;
    font-size: 1.6rem;
    color: #ee5340;
    letter-spacing: 0;
}
@media (min-width: 768px) {
    .tl3 {
        font-size: 1.8rem;
    }
}
@media (min-width: 992px) {
    .tl3 {
        font-size: 2rem;
    }
}
.tl3--light {
    font-family: "AribauGroteskLt", Verdana, sans-serif;
}

.mainHeader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    width: 100vw;
    height: 60px;
    background-color: #ee5340;
}
@media (min-width: 768px) {
    .mainHeader {
        width: 91px;
        height: 100vh;
    }
}
.mainHeader .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 230px;
    height: 17px;
    transform: translateX(-50%) translateY(-50%);
}
@media (min-width: 768px) {
    .mainHeader .logo {
        transform: translateX(-50%) translateY(-50%) rotate(-90deg);
        transform-origin: center center;
    }
}
.mainHeader .userName {
    position: absolute;
    top: 50%;
    left: 20px;
    display: block;
    width: 42px;
    height: 42px;
    overflow: hidden;
    line-height: 42px;
    text-align: center;
    transform: translateY(-50%);
}
@media (min-width: 768px) {
    .mainHeader .userName {
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
    }
}
.mainHeader .userName:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 42px;
    height: 42px;
    content: "";
    background-color: #fff;
    border-radius: 50%;
}
.mainHeader .logout {
    position: absolute;
    top: 50%;
    right: 20px;
    display: block;
    transform: translateX(-100%) translateY(-50%);
}
@media (min-width: 768px) {
    .mainHeader .logout {
        right: auto;
        top: auto;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.mainFooter {
    padding: 10px 28px;
    border-top: 1px solid #f1f2f3;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.form-group {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 15px 0 25px 0;
    line-height: normal;
}
.form-group input[type=text],
.form-group textarea,
.form-group select {
    display: block;
    width: 100%;
    padding: 6px 2px;
    font-family: "AribauGroteskLt", Verdana, sans-serif;
    border: 0;
    border-bottom: 1px solid #9E9EA9;
    border-radius: 0;
    background: transparent;
    -webkit-appearance: none;
}
.form-group input[type=search] {
    padding: 11px 50px 11px 20px;
    font-family: "AribauGroteskRg", Verdana, sans-serif;
    font-size: 1.6rem;
    border: 1px solid #1c24ea;
    border-radius: 24px;
    background: transparent url("./../../img/common/form/search.svg") no-repeat right 2rem center;
    background-size: 19px;
    -webkit-appearance: none;
}
.form-group select {
    background: transparent url("./../../img/common/form/selectArrow.svg") no-repeat right 0.75rem center;
    background-size: 13px 7px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.form-group textarea {
    width: 100%;
    margin-top: 20px;
    padding: 6px;
    border: 1px solid #9E9EA9;
    border-radius: 5px;
}
.form-group input[type=text],
.form-group textarea,
.form-group select {
    position: relative;
    z-index: 1;
}
.form-group label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    padding: 0 2px;
    text-align: left;
    color: #9E9EA9;
}
.form-group .select2-container {
    font-family: "AribauGroteskRg", Verdana, sans-serif;
    background: transparent;
}
.form-group .select2-container .select2-selection--single,
.form-group .select2-container .select2-selection--multiple {
    display: flex;
    height: auto;
    margin: 0;
    border: 0;
    border-radius: 0;
    background: transparent url("./../../img/common/form/selectArrow.svg") no-repeat right 0.75rem top 12px;
    background-size: 13px 7px;
}
.form-group .select2-container .select2-selection--single .select2-selection__rendered,
.form-group .select2-container .select2-selection--multiple .select2-selection__rendered {
    width: 100%;
    min-height: 28px;
    padding: 6px 30px 6px 2px;
    color: #000;
    line-height: normal;
    border-bottom: 1px solid #9E9EA9;
}
.form-group .select2-container .select2-selection--single .select2-selection__arrow {
    display: none;
}
.form-group .select2-container .select2-selection--single .select2-selection__clear {
    font-size: 1.6rem;
    line-height: 1;
}
.form-group .select2-container .select2-selection--multiple {
    min-height: 28px;
}
.form-group .select2-container .select2-selection--multiple .select2-selection__rendered {
    padding-top: 0;
    padding-bottom: 3px;
}
.form-group--inline {
    position: relative;
    display: inline-block;
    width: auto;
    margin-bottom: 0;
    padding: 0;
}
.form-group--inline input[type=checkbox], .form-group--inline input[type=radio] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}
.form-group--inline input[type=checkbox] + label, .form-group--inline input[type=radio] + label {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 16px;
    padding-left: 25px;
    vertical-align: bottom;
}
.form-group--inline input[type=checkbox] + label:before, .form-group--inline input[type=radio] + label:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: "";
    background-color: transparent;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 16px 40px;
}
.form-group--inline input[type=checkbox]:hover + label:before, .form-group--inline input[type=checkbox]:checked + label:before, .form-group--inline input[type=radio]:hover + label:before, .form-group--inline input[type=radio]:checked + label:before {
    background-position: 0 -24px;
}
.form-group--inline input[type=checkbox] + label:before {
    background-image: url("./../../img/common/form/checkbox.svg");
}
.form-group--inline input[type=radio] + label:before {
    background-image: url("./../../img/common/form/radiobutton.svg");
}
.form-group--inline label {
    color: #000;
}
.form-group--inline + .form-group--inline {
    padding-left: 50px;
}
.form-group .errorlist {
    position: absolute;
    bottom: 5px;
}
.form-group .errorlist + input, .form-group .errorlist + select {
    border-color: #ee5340;
}
.form-group .errorlist ~ .select2-container--default .select2-selection--single .select2-selection__rendered,
.form-group .errorlist ~ .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    border-color: #ee5340;
}

.form-input label {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-100%);
    transition: top 0.2s, transform 0.2s;
    display: block;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.form-input--filled input + label,
.form-input input:focus + label,
.form-input input:-webkit-autofill + label, .form-input--filled select + label,
.form-input select:focus + label {
    top: 0;
    transform: translateY(0);
    transition: top 0.2s, transform 0.2s;
}

.errorlist {
    color: #ee5340;
}

.select2-container {
    font-family: "AribauGroteskLt", Verdana, sans-serif;
    font-size: 1.4rem;
}

.mainLogin {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    justify-content: center;
}
.mainLogin #mainLogin__form {
    text-align: center;
}

.mainHome {
    font-size: 1.6rem;
}
@media (min-width: 768px) {
    .mainHome {
        display: flex;
        flex-direction: column;
        flex-grow: 2;
        justify-content: space-evenly;
    }
}
@media (min-width: 768px) {
    .mainHome .mainHome-line {
        display: flex;
        flex-direction: column;
        flex-grow: 2;
    }
}
.mainHome .mainHome-line .row {
    width: 100%;
}
.mainHome .mainHome-line .mainmainHome-line-title {
    text-align: center;
}
@media (min-width: 768px) {
    .mainHome .mainHome-line .mainHome-line-apps {
        display: flex;
        flex-grow: 2;
        flex-wrap: wrap;
    }
}
.mainHome [class*=col] {
    margin-top: 20px;
}
.mainHome .card {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    height: 100%;
    font-size: 2rem;
    text-align: center;
    border: 1px solid #DFE1E5;
}
.mainHome .card a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    text-align: center;
}
.mainHome .card a:hover {
    color: #ee5340;
}
.mainHome .card--muted {
    padding: 20px;
    color: #A6ADB9;
    background-color: rgba(223, 225, 229, 0.5);
}