$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-wd-min: 1400px;

$container-max-width-sm-min: 540px;
$container-max-width-md-min: 720px;
$container-max-width-lg-min: 960px;
$container-max-width-xl-min: 1140px;

// Grid columns
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;

// Grid breakpoints
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
    xs: 0,
    sm: $screen-sm-min,
    md: $screen-md-min,
    lg: $screen-lg-min,
    xl: $screen-xl-min,
    wd: $screen-wd-min
) !default;

// Fonts
$fontXLight:    'AribauGroteskXlt', Verdana, sans-serif;
$fontLight:     'AribauGroteskLt', Verdana, sans-serif;
$fontRegular:   'AribauGroteskRg', Verdana, sans-serif;
$fontMedium:    'AribauGroteskMd', Verdana, sans-serif;
$fontBold:      'AribauGroteskBd', Verdana, sans-serif;
$fontXBold:     'AribauGroteskBlk', Verdana, sans-serif;

// Colors
$white: #fff;
$black: #000;
$selection: #241a17;
$linkcolor: #ee5340;

$grayMedium: #383b41;
$grayLight: #9E9EA9;
$grayWhite: #f1f2f3;

$grayTable: #F4F5F7;
$grayTableBorder: #DFE1E5;
$grayTableText: #A6ADB9;

$blueBright: #1c24ea;

$red: #ee5340;

// colors contact intials
$yellow: #fceb85;
$green: #64cd84;
$eggplant: #30213b;
$purple: #7f6ecf;
$turquoise: #5895a8;
$gray: #383B40;

