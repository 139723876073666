.btn {
    display: inline-block;
    padding: 11px 20px;
    font-family: $fontRegular;
    font-size: 1.6rem;
    color: $white;
    text-align: left;
    border-radius: 24px;
    border: 1px solid $blueBright;
    background-color: $blueBright;

    &--ok {
        padding-right: 38px;
        background: $blueBright url('./../../img/common/pics/ok.png') no-repeat right 20px center;
        background-size: 14px 17px;
    }

    &--prev {
        padding-left: 38px;
        background: $blueBright url('./../../img/common/pics/prev_white.svg') no-repeat left 20px center;
        background-size: 7px 14px;
    }

    &--next {
        padding-right: 38px;
        background: $blueBright url('./../../img/common/pics/next_white.svg') no-repeat right 20px center;
        background-size: 7px 14px;
    }

    &--outline {
        color: $blueBright !important;
        background-color: $white;
    }

    &--delete {
        background-color: $red;
        border-color: $red;
    }

    &--deletePic {
        width: 20px;
        height: 20px;
        padding: 0;
        border: 0;
        background: transparent url('./../../img/common/pics/cross.svg') no-repeat 0 center;
        background-size: 20px 88px;
    }

    &--plus {
        display: flex;
        align-items: center;
        height: 44px;
        padding-left: 55px;
        color: $grayLight;
        background: transparent url('./../../img/common/pics/plus_small.svg') no-repeat 0 0;
        background-size: 44px 44px;
        border: 0;

        &--small {
            height: 30px;
            font-size: 1.4rem;
            padding-left: 41px;
            // background-image: url('./../../img/common/pics/plus_small.svg');
            background-size: 30px 30px;
        }
    }

    &--addContact {
        display: flex;
        align-items: center;
        height: 44px;
        padding-left: 55px;
        background: transparent url('./../../img/common/pics/plus_blue.svg') no-repeat left 1em center;
        background-size: 21px auto;
    }
}

.link {
    font-family: $fontRegular;
    font-size: 1.6rem;
    color: $blueBright;
    text-align: left;

    &--picto {
        padding-left: 35px;
        background-color: transparent;
        background-position: 0 center;
        background-repeat: no-repeat;
        background-size: 20px;
        
        &.basket { background-image: url('./../../img/common/pics/basket.svg'); }
        &.envelope { background-image: url('./../../img/common/pics/envelope.svg'); }
        &.pen { background-image: url('./../../img/common/pics/pen.svg'); }
        &.phone { background-image: url('./../../img/common/pics/phone.svg'); }
        &.trashcan { background-image: url('./../../img/common/pics/trashcan.svg'); }
    }
}

button.link {
    border: 0;
}