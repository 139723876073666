.pagination {
    margin: 25px 0;
    ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 1.4rem;
        text-align: center;
    }
    li {
        display: flex;
        align-items: center;
        justify-content: center;

        & + li {
            margin-left: 10px;
        }
        a {
            display: inline-block;
            width: 20px; height: 20px;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: 20px 8px;
        }
    }
    &__first a { background-image: url('./../../img/common/pics/first_gray.svg'); }
    &__prev a { background-image: url('./../../img/common/pics/prev_gray.svg'); }
    &__current a { }
    &__next a { background-image: url('./../../img/common/pics/next_gray.svg'); }
    &__last a { background-image: url('./../../img/common/pics/last_gray.svg'); }
}