.tableStriped {
    width: 100%;
    font-size: 1.4rem;
    border-bottom: 1px solid $grayTable;

    thead {
        color: $grayMedium;
        text-align: left;
        background-color: $grayTable;
        th {
            padding: 20px 30px;
            font-size: 1.2rem; 
            color: $grayMedium;
        }
    }

    th, td {
        padding: 10px 30px;
        color: $grayTableText;
        font-weight: normal;
        & + th,
        & + td {
            border-left: 1px solid $grayTableBorder;
        }
    }

    tbody tr:nth-of-type(even) {
        background-color: $grayTable;
    }
}