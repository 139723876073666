html {
    font-size: 62.5%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

body {
    margin: 0;
    font-size: 1em;
    line-height: 1.4;
}

*,
::before,
::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

::-moz-selection {
    background: $selection;
    color: #fff;
    text-shadow: none;
}
::selection {
    background: $selection;
    color: #fff;
    text-shadow: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
    display: block;
}

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
}
audio:not([controls]) {
    display: none;
}
[hidden] {
    display: none;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    display: inherit;
    font-size: inherit;
    font-weight: inherit;
}

a {
    cursor: pointer;
    text-decoration: none;
    &:hover, &:active, &:focus {
        outline: none;
    }
}

bbr[title] {
    border-bottom: 1px dotted;
}

b,
strong {
    font-weight: bold;
}

blockquote {
    margin: 1em 40px;
}

dfn {
    font-style: italic;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #cbc1b3;
    margin: 1.5em 0;
    padding: 0;
}

ins {
    background: #ff9;
    color: #000;
    text-decoration: none;
}

mark {
    background: #ff0;
    color: #000;
    font-style: italic;
    font-weight: bold;
}

pre,
code,
kbd,
samp {
    font-family: monospace,
    serif;
    _font-family: 'courier new',
    monospace;
    font-size: 1em;
}

pre {
    white-space: pre-wrap;
    word-wrap: break-word;
}

q {
    quotes: none;
}
q:before,
q:after {
    content: '';
}

small {
    font-size: 85%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}

ul,
ol {
    list-style: none;
    margin: 0;
    padding: 0;
}
dd {
    margin: 0 0 0 40px;
}

img {
    border: 0;
    -ms-interpolation-mode: bicubic;
    vertical-align: middle;
}

svg:not(:root) {
    overflow: hidden;
}

figure {
    margin: 0;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}
td {
    vertical-align: top;
}



/* form reset */
form {
    margin: 0;
}
fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}
label {
    cursor: pointer;
}
legend {
    border: 0;
    *margin-left: -7px;
    padding: 0;
    white-space: normal;
}
button,
input,
select,
textarea {
    font-size: 100%;
    margin: 0;
    vertical-align: baseline;
    *vertical-align: middle;
    outline: none;
}
button,
input {
    line-height: normal;
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    cursor: pointer;
    -webkit-appearance: button;
    *overflow: visible;
}
button[disabled],
input[disabled] {
    cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
    *width: 13px;
    *height: 13px;
}
input[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}
input:invalid,
textarea:invalid {
    background-color: #f0dddd;
}
