html {
    font-size: 62.5%;
}

html,
body {
    height: 100%;
}

body {
    font-family: $fontRegular;
    font-size: 1em;
    color: $black;
    background-color: $white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.wrapper {
    font-size: 1.2rem;
}

a {
    color: $blueBright;
//     &:hover, &:active, &:focus {
//         color: $linkcolor;
//     }
}

.tl1 {
    font-family: $fontLight;
    font-size: 4rem;
    color: $red;
    letter-spacing: 0;
    
    &--center { text-align: center; }

    @include media-breakpoint-up(md) {
        font-size: 6rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 8rem;
    }
}

.tl2 {
    font-family: $fontLight;
    font-size: 2rem;
    color: $red;
    letter-spacing: 0;
        
    &--center { text-align: center; }

    @include media-breakpoint-up(md) {
        font-size: 4rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 6rem;
    }
}

.tl3 {
    font-family: $fontBold;
    font-size: 1.6rem;
    color: $red;
    letter-spacing: 0;
    @include media-breakpoint-up(md) {
        font-size: 1.8rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 2rem;
    }

    &--light {
        font-family: $fontLight;
    }
}