@use "sass:math";

.col-wd-1,
.col-wd-2,
.col-wd-3,
.col-wd-4,
.col-wd-5,
.col-wd-6,
.col-wd-7,
.col-wd-8,
.col-wd-9,
.col-wd-10,
.col-wd-11,
.col-wd-12,
.col-wd,
.col-wd-auto {
    position: relative;
    width: 100%;
    padding-right: math.div($grid-gutter-width, 2);
    padding-left: math.div($grid-gutter-width, 2);
}

@include media-breakpoint-up(wd) {
    .col-wd {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-wd-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .col-wd-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
    .col-wd-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-wd-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-wd-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-wd-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-wd-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-wd-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    .col-wd-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-wd-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-wd-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-wd-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
    .col-wd-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-wd-first {
        -ms-flex-order: -1;
        order: -1;
    }
    .order-wd-last {
        -ms-flex-order: 13;
        order: 13;
    }
    .order-wd-0 {
        -ms-flex-order: 0;
        order: 0;
    }
    .order-wd-1 {
        -ms-flex-order: 1;
        order: 1;
    }
    .order-wd-2 {
        -ms-flex-order: 2;
        order: 2;
    }
    .order-wd-3 {
        -ms-flex-order: 3;
        order: 3;
    }
    .order-wd-4 {
        -ms-flex-order: 4;
        order: 4;
    }
    .order-wd-5 {
        -ms-flex-order: 5;
        order: 5;
    }
    .order-wd-6 {
        -ms-flex-order: 6;
        order: 6;
    }
    .order-wd-7 {
        -ms-flex-order: 7;
        order: 7;
    }
    .order-wd-8 {
        -ms-flex-order: 8;
        order: 8;
    }
    .order-wd-9 {
        -ms-flex-order: 9;
        order: 9;
    }
    .order-wd-10 {
        -ms-flex-order: 10;
        order: 10;
    }
    .order-wd-11 {
        -ms-flex-order: 11;
        order: 11;
    }
    .order-wd-12 {
        -ms-flex-order: 12;
        order: 12;
    }
    .offset-wd-0 {
        margin-left: 0;
    }
    .offset-wd-1 {
        margin-left: 8.333333%;
    }
    .offset-wd-2 {
        margin-left: 16.666667%;
    }
    .offset-wd-3 {
        margin-left: 25%;
    }
    .offset-wd-4 {
        margin-left: 33.333333%;
    }
    .offset-wd-5 {
        margin-left: 41.666667%;
    }
    .offset-wd-6 {
        margin-left: 50%;
    }
    .offset-wd-7 {
        margin-left: 58.333333%;
    }
    .offset-wd-8 {
        margin-left: 66.666667%;
    }
    .offset-wd-9 {
        margin-left: 75%;
    }
    .offset-wd-10 {
        margin-left: 83.333333%;
    }
    .offset-wd-11 {
        margin-left: 91.666667%;
    }


    .d-wd-none {
        display: none !important;
    }
    .d-wd-inline {
        display: inline !important;
    }
    .d-wd-inline-block {
        display: inline-block !important;
    }
    .d-wd-block {
        display: block !important;
    }
    .d-wd-table {
        display: table !important;
    }
    .d-wd-table-row {
        display: table-row !important;
    }
    .d-wd-table-cell {
        display: table-cell !important;
    }
    .d-wd-flex {
        display: -ms-flexbox !important;
        display: flex !important;
    }
    .d-wd-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }

    
    .flex-wd-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }
    .flex-wd-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }
    .flex-wd-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }
    .flex-wd-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }
    .flex-wd-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }
    .flex-wd-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }
    .flex-wd-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }
    .flex-wd-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }
    .flex-wd-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }
    .flex-wd-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }
    .flex-wd-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }
    .flex-wd-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }
    .justify-content-wd-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }
    .justify-content-wd-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }
    .justify-content-wd-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }
    .justify-content-wd-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }
    .justify-content-wd-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }
    .align-items-wd-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }
    .align-items-wd-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }
    .align-items-wd-center {
        -ms-flex-align: center !important;
        align-items: center !important;
    }
    .align-items-wd-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }
    .align-items-wd-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }
    .align-content-wd-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }
    .align-content-wd-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }
    .align-content-wd-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }
    .align-content-wd-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }
    .align-content-wd-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }
    .align-content-wd-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }
    .align-self-wd-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }
    .align-self-wd-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }
    .align-self-wd-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }
    .align-self-wd-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }
    .align-self-wd-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }
    .align-self-wd-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }
    

    .m-wd-0 {
        margin: 0 !important;
    }
    .mt-wd-0,
    .my-wd-0 {
        margin-top: 0 !important;
    }
    .mr-wd-0,
    .mx-wd-0 {
        margin-right: 0 !important;
    }
    .mb-wd-0,
    .my-wd-0 {
        margin-bottom: 0 !important;
    }
    .ml-wd-0,
    .mx-wd-0 {
        margin-left: 0 !important;
    }
    .m-wd-1 {
        margin: 0.25rem !important;
    }
    .mt-wd-1,
    .my-wd-1 {
        margin-top: 0.25rem !important;
    }
    .mr-wd-1,
    .mx-wd-1 {
        margin-right: 0.25rem !important;
    }
    .mb-wd-1,
    .my-wd-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-wd-1,
    .mx-wd-1 {
        margin-left: 0.25rem !important;
    }
    .m-wd-2 {
        margin: 0.5rem !important;
    }
    .mt-wd-2,
    .my-wd-2 {
        margin-top: 0.5rem !important;
    }
    .mr-wd-2,
    .mx-wd-2 {
        margin-right: 0.5rem !important;
    }
    .mb-wd-2,
    .my-wd-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-wd-2,
    .mx-wd-2 {
        margin-left: 0.5rem !important;
    }
    .m-wd-3 {
        margin: 1rem !important;
    }
    .mt-wd-3,
    .my-wd-3 {
        margin-top: 1rem !important;
    }
    .mr-wd-3,
    .mx-wd-3 {
        margin-right: 1rem !important;
    }
    .mb-wd-3,
    .my-wd-3 {
        margin-bottom: 1rem !important;
    }
    .ml-wd-3,
    .mx-wd-3 {
        margin-left: 1rem !important;
    }
    .m-wd-4 {
        margin: 1.5rem !important;
    }
    .mt-wd-4,
    .my-wd-4 {
        margin-top: 1.5rem !important;
    }
    .mr-wd-4,
    .mx-wd-4 {
        margin-right: 1.5rem !important;
    }
    .mb-wd-4,
    .my-wd-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-wd-4,
    .mx-wd-4 {
        margin-left: 1.5rem !important;
    }
    .m-wd-5 {
        margin: 3rem !important;
    }
    .mt-wd-5,
    .my-wd-5 {
        margin-top: 3rem !important;
    }
    .mr-wd-5,
    .mx-wd-5 {
        margin-right: 3rem !important;
    }
    .mb-wd-5,
    .my-wd-5 {
        margin-bottom: 3rem !important;
    }
    .ml-wd-5,
    .mx-wd-5 {
        margin-left: 3rem !important;
    }
    .p-wd-0 {
        padding: 0 !important;
    }
    .pt-wd-0,
    .py-wd-0 {
        padding-top: 0 !important;
    }
    .pr-wd-0,
    .px-wd-0 {
        padding-right: 0 !important;
    }
    .pb-wd-0,
    .py-wd-0 {
        padding-bottom: 0 !important;
    }
    .pl-wd-0,
    .px-wd-0 {
        padding-left: 0 !important;
    }
    .p-wd-1 {
        padding: 0.25rem !important;
    }
    .pt-wd-1,
    .py-wd-1 {
        padding-top: 0.25rem !important;
    }
    .pr-wd-1,
    .px-wd-1 {
        padding-right: 0.25rem !important;
    }
    .pb-wd-1,
    .py-wd-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-wd-1,
    .px-wd-1 {
        padding-left: 0.25rem !important;
    }
    .p-wd-2 {
        padding: 0.5rem !important;
    }
    .pt-wd-2,
    .py-wd-2 {
        padding-top: 0.5rem !important;
    }
    .pr-wd-2,
    .px-wd-2 {
        padding-right: 0.5rem !important;
    }
    .pb-wd-2,
    .py-wd-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-wd-2,
    .px-wd-2 {
        padding-left: 0.5rem !important;
    }
    .p-wd-3 {
        padding: 1rem !important;
    }
    .pt-wd-3,
    .py-wd-3 {
        padding-top: 1rem !important;
    }
    .pr-wd-3,
    .px-wd-3 {
        padding-right: 1rem !important;
    }
    .pb-wd-3,
    .py-wd-3 {
        padding-bottom: 1rem !important;
    }
    .pl-wd-3,
    .px-wd-3 {
        padding-left: 1rem !important;
    }
    .p-wd-4 {
        padding: 1.5rem !important;
    }
    .pt-wd-4,
    .py-wd-4 {
        padding-top: 1.5rem !important;
    }
    .pr-wd-4,
    .px-wd-4 {
        padding-right: 1.5rem !important;
    }
    .pb-wd-4,
    .py-wd-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-wd-4,
    .px-wd-4 {
        padding-left: 1.5rem !important;
    }
    .p-wd-5 {
        padding: 3rem !important;
    }
    .pt-wd-5,
    .py-wd-5 {
        padding-top: 3rem !important;
    }
    .pr-wd-5,
    .px-wd-5 {
        padding-right: 3rem !important;
    }
    .pb-wd-5,
    .py-wd-5 {
        padding-bottom: 3rem !important;
    }
    .pl-wd-5,
    .px-wd-5 {
        padding-left: 3rem !important;
    }
    .m-wd-n1 {
        margin: -0.25rem !important;
    }
    .mt-wd-n1,
    .my-wd-n1 {
        margin-top: -0.25rem !important;
    }
    .mr-wd-n1,
    .mx-wd-n1 {
        margin-right: -0.25rem !important;
    }
    .mb-wd-n1,
    .my-wd-n1 {
        margin-bottom: -0.25rem !important;
    }
    .ml-wd-n1,
    .mx-wd-n1 {
        margin-left: -0.25rem !important;
    }
    .m-wd-n2 {
        margin: -0.5rem !important;
    }
    .mt-wd-n2,
    .my-wd-n2 {
        margin-top: -0.5rem !important;
    }
    .mr-wd-n2,
    .mx-wd-n2 {
        margin-right: -0.5rem !important;
    }
    .mb-wd-n2,
    .my-wd-n2 {
        margin-bottom: -0.5rem !important;
    }
    .ml-wd-n2,
    .mx-wd-n2 {
        margin-left: -0.5rem !important;
    }
    .m-wd-n3 {
        margin: -1rem !important;
    }
    .mt-wd-n3,
    .my-wd-n3 {
        margin-top: -1rem !important;
    }
    .mr-wd-n3,
    .mx-wd-n3 {
        margin-right: -1rem !important;
    }
    .mb-wd-n3,
    .my-wd-n3 {
        margin-bottom: -1rem !important;
    }
    .ml-wd-n3,
    .mx-wd-n3 {
        margin-left: -1rem !important;
    }
    .m-wd-n4 {
        margin: -1.5rem !important;
    }
    .mt-wd-n4,
    .my-wd-n4 {
        margin-top: -1.5rem !important;
    }
    .mr-wd-n4,
    .mx-wd-n4 {
        margin-right: -1.5rem !important;
    }
    .mb-wd-n4,
    .my-wd-n4 {
        margin-bottom: -1.5rem !important;
    }
    .ml-wd-n4,
    .mx-wd-n4 {
        margin-left: -1.5rem !important;
    }
    .m-wd-n5 {
        margin: -3rem !important;
    }
    .mt-wd-n5,
    .my-wd-n5 {
        margin-top: -3rem !important;
    }
    .mr-wd-n5,
    .mx-wd-n5 {
        margin-right: -3rem !important;
    }
    .mb-wd-n5,
    .my-wd-n5 {
        margin-bottom: -3rem !important;
    }
    .ml-wd-n5,
    .mx-wd-n5 {
        margin-left: -3rem !important;
    }
    .m-wd-auto {
        margin: auto !important;
    }
    .mt-wd-auto,
    .my-wd-auto {
        margin-top: auto !important;
    }
    .mr-wd-auto,
    .mx-wd-auto {
        margin-right: auto !important;
    }
    .mb-wd-auto,
    .my-wd-auto {
        margin-bottom: auto !important;
    }
    .ml-wd-auto,
    .mx-wd-auto {
        margin-left: auto !important;
    }
}
